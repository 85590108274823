export function wmaticContractLoaded(wmatic) {
  return {
    type: 'WMATIC_CONTRACT_LOADED',
    wmatic
  }
}

export function wmaticMasterChefBalanceLoaded(wmaticBal) {
  return {
    type: 'WMATIC_MASTERCHEFBAL_LOADED',
    wmaticBal
  }
}

export function wmaticPoolDataLoaded(wmaticPoolData) {
  return {
    type: 'WMATIC_POOL_DATA_LOADED',
    wmaticPoolData
  }
}

export function wmaticUserInfoLoaded(wmaticUserInfo) {
  return {
    type: 'WMATIC_USER_INFO_LOADED',
    wmaticUserInfo
  }
}