export function usdcContractLoaded(usdc) {
  return {
    type: 'USDC_CONTRACT_LOADED',
    usdc
  }
}

export function usdcMasterChefBalanceLoaded(usdcBal) {
  return {
    type: 'USDC_MASTERCHEFBAL_LOADED',
    usdcBal
  }
}

export function usdcPoolDataLoaded(usdcPoolData) {
  return {
    type: 'USDC_POOL_DATA_LOADED',
    usdcPoolData
  }
}

export function usdcUserInfoLoaded(usdcUserInfo) {
  return {
    type: 'USDC_USER_INFO_LOADED',
    usdcUserInfo
  }
}