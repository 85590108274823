import { combineReducers } from 'redux';
import { cubbyNFTContract, ccash, cubbymarket } from './cubby/reducers'
import DeFiBankReducer from './bank/reducers'
import SOReducer from './spaceodyssey/reducers'


function web3(state = {}, action) {
  switch (action.type) {
    case 'WEB3_LOADED':
      return { ...state, connection: action.connection }
    case 'WEB3_NETWORK_LOADED':
      return { ...state, network: action.network }
    case 'WEB3_ACCOUNT_LOADED':
      return { ...state, account: action.account }
    case 'WEB3_BALANCE_LOADED':
      return { ...state, balance: action.balance }
      case 'CCASH_BALANCE_LOADED':
        return { ...state, ccashBal: action.ccashBal }
    default:
      return state
  }
}


const rootReducer = combineReducers({
  web3,
  cubbyNFTContract,
  ccash,
  cubbymarket,
  DeFiBankReducer,
  SOReducer
})

export default rootReducer