import React, { Component } from 'react'
import { connect } from 'react-redux'

import backgroundImg from '../../images/SOSPoster.png'


import {
  soMarketSelector,
  // soNFTSelector
} from '../../store/spaceodyssey/selectors'

class SOLoading extends Component {
  render() {
    return (
      <div className="loading text-center centered" style={{ color: "#ffffff" }}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        make sure you connect your wallet to Polygon!
        <br></br>
        <br></br>
        <div className="main"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'justify-flex',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // width: '100%',
            // height: '100%'
          }}>
        </div>
        <div style={{ color: '#000000' }}>



          <div style={{ padding: '7px' }}>
            <h3>SpaceOdyssey's Market by Coinopolis</h3>
            <div>
              <a href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank' rel="noopener noreferrer">
                <button className='btn btn-primary'>Buy $CCASH</button></a>
            </div>

            {this.props.soNFTs === undefined ? <div> loading </div> :
              <div className="cubbybox-container">
                {this.props.soNFTs.map((nft, key) => {
                  return (


                    <div className="cubbybox-item" key={key}>

                      <h5>MarketID {nft.nftMarketID}</h5>
                      <a href={nft.tokenURIData.image} target="_blank" rel="noopener noreferrer">
                        <figure>
                          <img
                            src={nft.tokenURIData.image}
                            alt='nft icon'
                            className="img-fluid"
                            style={{ width: '95%' }}
                          />
                        </figure>
                      </a>
                      <div>
                        {nft.listed ?
                          <div>
                            <p>{nft.price / 10 ** 18} MATIC</p>
                            <p>+</p>
                            <p>{nft.ccashFee} CCASH</p>
                          </div>
                          :
                          <div>
                            last listed price :
                            <p>{nft.price / 10 ** 18} MATIC</p>
                            <p>+</p>
                            <p>{nft.ccashFee} CCASH</p>
                          </div>
                        }



                      </div>

                    </div>


                  )
                })}</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    soNFTs: soMarketSelector(state),
    // nftURI: soNFTSelector(state)
  }
}

export default connect(mapStateToProps)(SOLoading)