import React from 'react'
import Breadcrumb from '../spaceodyssey/BreadcrumbSO'
import Navbar3 from '../Navbar/Navbar3'
import FooterNew from '../FooterNew'
import Blogdata from './BlogData'
import blogimg from "../../images/blog/boostNFTs.png";
// import coinopolisHome from "../../images/blog/coinopolis-home.png";
// import connectWallet from "../../images/blog/connect-wallet.png";
// import coinopolisSell from "../../images/blog/coinopolisSell.png";
// import coinopolisSO from "../../images/blog/coinopolisSO.png";
import { Link } from 'react-router-dom'

const BlogSingle = () => {



    return (
        <>
            <Navbar3 />
            <Breadcrumb name="Blog" />
            <section className="single featured post-details">
                <div className="container">
                    <div className="row">
                        {/* Main */}
                        <div className="col-12 col-lg-10 p-0">
                            <div className="row">
                                <div className="col-12 align-self-center">
                                    {/* Image */}
                                    <div className="blog-thumb">
                                        <img
                                            className="w-100"
                                            src={blogimg}
                                            alt="Blog-img"
                                        />
                                    </div>
                                    <h2 className="featured ml-0">
                                    Crypto, Blockchain, NFT, Metaverse Keyterms
                                    </h2>
                                    <p>
                                    metaverse? NFTs? Blockchain? \ There are many words floating around crypto. So we decided to give you a brief definition of each keyterm! 
                                    </p>
                                    {/* <blockquote>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam
                                        tortor, ultrices accumsan mauris eget, pulvinar tincidunt erat.
                                        Sed nisi nisi, rutrum sit amet elit.
                                    </blockquote> */}
                                    <h3>Cryptocurrency</h3>
                                    <p>
                                    Cryptocurrencies are digital currencies that do not rely on banks or third parties to transfer value.
                                    </p>
                                    {/* <h3>Tokens</h3>
                                    <p>
                                    Tokens are 
                                    </p> */}                                    
                                    
                                    {/* <h3>Tokens</h3>
                                    <p>
                                    Tokens are 
                                    </p> */}
                                    <h3>Blockchain</h3>
                                    <p>
                                        Blockcahins are digital ledgers distributed across a network of nodes. They are a way store data while making it difficult for a bad actor to manipulate or cheat the system. 
                                        <br /><br />
                                        There are many blockchains out there, the most notable chains are Bitcoin and Ethereum. Two very different types of blockchains. One has focused on being a digital currency, while the other is programmable money using smart contracts.
                                        <br /><br />

                                        There are many blockchains out there, the most notable chains are Bitcoin and Ethereum. Two very different types of blockchains. One has focused on being a digital currency, while the other is programmable money using smart contracts.
                                        <br /><br />
                                    </p>

                                    <h3>Smart Contracts</h3>
                                    <p>
                                    A smart contract is executable code, almost always immutable, that is stored on a blockchain that users can interact with.  
                                    {/* <br /> Explore Coinopolis <Link to="/SOMarket">NFT marketplace</Link></p> */}
                                    </p>

                                    <h3>NFT</h3>
                                    <p>
                                        An NFT is a digital good that is back by blockchain. These can resemble many things, such as items in a metaverse or ownship of intellectual property.
                                        <br /><br />
                                        NFTs are an easy way to prove ownership of an item. 
                                        <br /><br />
                                    </p>

                                    <h3>Fungible vs Non-fungible Token?</h3>
                                    <p>
                                        Fungible Tokens can be traded one-for-one. One United States Dollar can be evenly exchanged for one United States Dollar. One Bitcoin is one Bitcoin. One Ether is one Ether...
                                        <br /><br />
                                        Non Funbible Tokens cannot be swapped in a one-for-one exchange. Think of a digital version of you shoes. One pair of shoes does not equal a different pair of shoes. Or a piece of art by on artist doesn't equal a different piece of art, even if it is by the same artist.
                                        <br /><br />
                                        We are going to leave it at that! Just don't overthink it!
                                        <br /><br />
                                    </p>

                                    <h3>Metaverse</h3>
                                    <p>
                                        Oh the Metaverse! Sounds wildly futuristic. Metaverses have been around for a while now. You can think of them as Diablo 2, World of Warcraft, Runescape...
                                        <br /><br />
                                        However, when most people talk about the metaverse, they are talking about digital realities where users can own digital goods,
                                        prove ownship of the digital goods, and easily transfer goods and services.
                                        <br /><br />
                                        Basically, they are digital realities or games that use crypto (blockchain) technology.
                                        <br /><br />
                                    </p>
{/* 
                                    <h3>Metadata</h3>
                                    <p>

                                    </p> */}

                                    <h3>DeFi and GameFi</h3>
                                    <p>
                                        DeFi, or Decentralized Finance, is a category of financail products that are available on decentralized (sometimes not so decentralized) networks. Ethereum is the most popular.
                                        <br /><br />
                                        GameFi is a combination of gaming and decentralized finance. Coinopolis is part of the GameFi ecosystem. Think of a game you currently play, but where you are playing to earn digital assets.
                                        <br /><br />
                                    </p>
                                    <h3>DAPPs</h3>
                                    <p>
                                        DAPPs are decentralized applications. They are applications that run on decentalized networks.
                                    </p>

                                    {/* Post Holder */}
                                    <ul className="mb-5 post-holder">
                                        <li className="post-meta-item">
                                            <div className="date">
                                                <span className="posted-on">
                                                    Posted On
                                                    <span>
                                                        <time
                                                            className="entry-date published updated"
                                                            dateTime="2018-11-01T06:18:46+00:00"
                                                        >
                                                            &nbsp; Dec 20, 2021
                                                        </time>
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                    {/* Comments */}
                                    <h3 className="comments-reply-text mb-3">Leave a Reply</h3>
                                    <p className="mt-3">
                                        Your email address will not be published. required fields are marked *
                                    </p>
                                    <form>
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-12 col-lg-6 input-group p-0 pr-lg-3 mb-3">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        data-minlength={3}
                                                        placeholder="Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6 input-group p-0 mb-3">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        data-minlength={3}
                                                        placeholder="Email"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 input-group p-0 mb-3">
                                                    <textarea
                                                        name="message"
                                                        data-minlength={3}
                                                        rows={4}
                                                        placeholder="Not Available Right Now"
                                                        required
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 input-group p-0">
                                                <a className="btn btn-bordered-white text-white">
                                                    POST COMMENT
                                                    <i className="icon-login ml-2" />
                                                </a>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterNew />

        </>
    )
}

export default BlogSingle;