import CCASH from '../../../backEnd/abis/ERC20/masterchef/CCASH.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  ccashContractLoaded,
  ccashUserInfoLoaded,
  ccashMasterChefBalanceLoaded,
  ccashPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadCCASH = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      // let provider = "https://polygon-mumbai.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"

      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const ccash = new web3.eth.Contract(CCASH.abi, CCASH.networks[netId].address)
      dispatch(ccashContractLoaded(ccash))
      return ccash
    } else {
      const ccash = new web3.eth.Contract(CCASH.abi, CCASH.networks[netId].address)
      dispatch(ccashContractLoaded(ccash))
      return ccash
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(ccashContractLoaded(null))
    return null
  }
}



export const ccashUpdate = async (dispatch) => {
  try{
    let account, web3, netId, ccash, masterchef, masterChefAddy
    let poolID = 1

    await update(dispatch)

    web3 = await loadWeb3Bank(dispatch)

    account = await loadAccount(dispatch)
    netId = await web3.eth.net.getId()

    if(web3 === undefined || netId !== 137) {
      // let provider = "https://polygon-mumbai.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      ccash = new web3.eth.Contract(CCASH.abi, CCASH.networks[netId].address)
      masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)
  
      ccash = await loadCCASH(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
      await getCCASHBalance(dispatch, ccash, masterChefAddy, account)
      await fetchCCASHPoolData(dispatch, poolID, masterchef)
      await CCASHUserInfo(dispatch, account, poolID, masterchef)
    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      ccash = await loadCCASH(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
      await getCCASHBalance(dispatch, ccash, masterChefAddy, account)
      await fetchCCASHPoolData(dispatch, poolID, masterchef)
      await CCASHUserInfo(dispatch, account, poolID, masterchef)
    }

  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const getCCASHBalance = async (dispatch, ccash, masterChefAddy, account) => {
  try{
    let mcCCASHBal, userCCASHBal, allowance
    let ccashBalance = []
    let ccashApproveBool = false
    mcCCASHBal = await ccash.methods.balanceOf(masterChefAddy).call() / 10**18
    console.log(account)
    if(account !== null) {
      userCCASHBal = await ccash.methods.balanceOf(account).call() / 10**18
      allowance = await ccash.methods.allowance(account, masterChefAddy).call()  / 10**18

      if(allowance >= userCCASHBal) {
        ccashApproveBool = true
      }
    } else {
      userCCASHBal = 0
    }

    ccashBalance[0] = {
      mcCCASHBal,
      userCCASHBal,
      ccashApproveBool
    }

    dispatch(ccashMasterChefBalanceLoaded(ccashBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}


export const fetchCCASHPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let ccashPoolInfo
    // CCASH poolID = 1
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    ccashPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }

    dispatch(ccashPoolDataLoaded(ccashPoolInfo))
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const CCASHUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let ccashUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _ccashPendingCCash = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let ccashPendingCCCASH = _ccashPendingCCash / 10**18
      ccashUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**18,
        ccashPendingCCCASH
      }
      dispatch(ccashUserInfoLoaded(ccashUserInfo))
    } else {
      ccashUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        ccashPendingCCCASH: 0
      }
      dispatch(ccashUserInfoLoaded(ccashUserInfo))
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const approveCCASH = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let ccash = await loadCCASH(dispatch, web3, netId)
  try {
    let amount = 1000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await ccash.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        ccashUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositCCASH = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 1
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        ccashUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawCCASH = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 1
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        ccashUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

