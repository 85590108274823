import React from 'react'
import Breadcrumb from '../spaceodyssey/BreadcrumbSO'
import Navbar3 from '../Navbar/Navbar3'
import FooterNew from '../FooterNew'
import Blogdata from './BlogData'
import blogimg from "../../images/coinopolis-nft.jpg";
import coinopolisHome from "../../images/blog/coinopolis-home.png";
import connectWallet from "../../images/blog/connect-wallet.png";
import coinopolisSell from "../../images/blog/coinopolisSell.png";
import coinopolisSO from "../../images/blog/coinopolisSO.png";
import { Link } from 'react-router-dom'

const BlogSingle = () => {



    return (
        <>
            <Navbar3 />
            <Breadcrumb name="Blog" />
            <section className="single featured post-details">
                <div className="container">
                    <div className="row">
                        {/* Main */}
                        <div className="col-12 col-lg-10 p-0">
                            <div className="row">
                                <div className="col-12 align-self-center">
                                    {/* Image */}
                                    <div className="blog-thumb">
                                        <img
                                            className="w-100"
                                            src={blogimg}
                                            alt="Blog-img"
                                        />
                                    </div>
                                    <h2 className="featured ml-0">
                                        How to buy and Sell NFTs
                                    </h2>
                                    <p>
                                    Are you a someone who is looking for a platform to buy and sell NFTs or digital artwork?
                                    If the answer is "yes" then let me introduce you to Coinopolis, the place for NFTs and the metaverse! NFTs can be bought or sold here! It’s an amazing way to have some fun! 
                                    </p>
                                    {/* <blockquote>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam
                                        tortor, ultrices accumsan mauris eget, pulvinar tincidunt erat.
                                        Sed nisi nisi, rutrum sit amet elit.
                                    </blockquote> */}
                                    <p>
                                    Non-fungible tokens have taken the internet by a storm and have created quite the hype in the global crypto markets. Buying and selling of NFTs have been trending as a fantastic way for artists to showcase their art while people are willing to swap crytpo for them.
                                    Keep reading to know more on metaverse, NFT and crypto art, gameFi, and web3 if you are looking forward to starting your own thing or wondering how to buy an NFT.
                                    </p>
                                    <h3>Getting To Know The Crypto World</h3>
                                    <p>
                                        Metaverse and GameFi, NFTs, and Web3 are the some trendy concepts in the crypto world. The industry is still forming a unified concept about them, and their relationship is often discussed on different platforms. 
                                        <br /><br />
                                        Metaverse is a virtual space, that exists in the digital world. As the industry develops, the coverage of metaverse is expected to expand and see explosive growth.

                                        As development progresses, the NFT in the metaverse will become a more significant part of the infrastructure. NFTs typically are digital items that are backed by blockchain. These have proved to be a trustworthy source of mapping things to the meta-universe from the real world.
                                        <br /><br />
                                        GameFi deals with gaming and finance, and of course, is seeing tremendous growth in recent months. It may have started as a niche, but going forward, it is expected to touch the social life and social interactions of gamers. Here at Coinopolis, we see GameFi revolutionizing the gaming industry.
                                        <br /><br />
                                        A key feature of Web3 is decentralization that sets it apart from its predecessors. Other features of web3, such as self-governing, distributed, and robust platforms promotes enhanced internet usage. Seen as hopeful the the answer of censorship. The applications built and developed with web3 do not run on a single node (or server), but spread out on multiple nodes.


                                    </p>
                                    <h3>How to Make an NFT</h3>
                                    <p>The first thing to consider while learning to make an NFT art is deciding on the blockchain on which the NFTs are going to be issued.
                                        Ethereum is a good option to start with since the Ethereum blockchain is popular blockchain, since it has a tremendous user base. However, this comes with a cost and we are seeing a lot of growth on Ethereum Layer 2s such as Polygon, AVAX, Fantom, Binance Smart Chain, etc.<br /><br />
                                        </p>
                                    <h3>Step 1: Getting Your Hands On A Digital Wallet</h3>
                                    <p>
                                    Wallets are key pieces to blockchain infrustructure, as they are used to interact with the blochain. The most widely used wallet for Ethereum users is MetaMask.
                                    MetaMask is working on building ERC-721 and ERC-1155 (geek terms for types of NFTs) improved viewing capability. 
                                    <br /> Explore Coinopolis <Link to="/SOMarket">NFT marketplace</Link></p>

                                    <h3>Step 2: Lay Groundwork and Select an NFT Marketplace</h3>
                                    <img src={coinopolisHome} className='img-fluid mb-2' alt="opensea Home" />
                                    <p className='link3'>Once you’ve secured a digital wallet, the next step is to lay the groundwork and select a marketplace for the purchase of NFTs: <a href="https://coinopolis.io/" > Coinopolis</a>, <a href="https://coinopolis.io/CryptoCubbies" > CryptoCubbies</a>, and <a href="https://coinopolis.io/SOMarket" > Space Odyssey</a> to name a few.<br /><br />
                                        If you are interested in listing on Coinopolis, please email <a href="mailto:info@coinopolis.io">info@coinopolis.io</a>
                                    </p>

                                    <h3>Step 3: Connect Your Wallet</h3>
                                    <img src={connectWallet} className='img-fluid mb-2' alt="Openseo Connect Wallet" />
                                    <p> DAPPs (Decentralized Apps or Web3 Apps), such as an NFT Marketplace, will have a ‘Connect’ button. You can use it to connect your digital wallet to the marketplace. </p>

                                    <h3>Step 4: Purchase your NFT</h3>
                                    <img src={coinopolisSO} className='img-fluid mb-2' alt="approve Nft" />
                                    <p>To purchase an NFT, navigate to the NFT marketplace that you would like to purchase from. Some marketplaces on Coinopolis will require you to approve Coinopolis to transfer your Coinopolis Cash.
                                        To do this, you will see an 'UNLOCK' button. Click on the button and sign the transaction that pops up in your wallet. You will now be able to purchase an NFT by clicking on the 'Buy' button.
                                        Ensure you have the funds to purchase the NFT, otherwise you will encounter an error in MetaMask </p>
                                    <h3>How to Sell Your NFT</h3>
                                    <img src={coinopolisSell} className='img-fluid mb-2' alt="Opensea Sell NFT" />

                                    <p>
                                        Not all marketplaces at Coinopolis allow users to list their NFTs. If you are able to list the NFTs on a Coinopolis Marketplace, you will see a 'List' button on the NFT in your Coinopolis wallet.
                                        Listing is simple. Again, you will need to approve Coinopolis to transfer your NFT to the marketplace. To do this, you click on the 'UNLOCK' button (only needed to be done once) and sign the transaction in MetaMask.
                                        Once done, enter a price you wish to sell the NFT for, and click 'List'! Yes, it is that simple!
                                    </p>
                                    {/* Post Holder */}
                                    <ul className="mb-5 post-holder">
                                        <li className="post-meta-item">
                                            <div className="date">
                                                <span className="posted-on">
                                                    Posted On
                                                    <span>
                                                        <time
                                                            className="entry-date published updated"
                                                            dateTime="2018-11-01T06:18:46+00:00"
                                                        >
                                                            &nbsp; Dec 14, 2021
                                                        </time>
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                    {/* Comments */}
                                    <h3 className="comments-reply-text mb-3">Leave a Reply</h3>
                                    <p className="mt-3">
                                        Your email address will not be published. required fields are marked *
                                    </p>
                                    <form>
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-12 col-lg-6 input-group p-0 pr-lg-3 mb-3">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        data-minlength={3}
                                                        placeholder="Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6 input-group p-0 mb-3">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        data-minlength={3}
                                                        placeholder="Email"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 input-group p-0 mb-3">
                                                    <textarea
                                                        name="message"
                                                        data-minlength={3}
                                                        rows={4}
                                                        placeholder="Not Available Right Now"
                                                        required
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 input-group p-0">
                                                <a className="btn btn-bordered-white text-white">
                                                    POST COMMENT
                                                    <i className="icon-login ml-2" />
                                                </a>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterNew />

        </>
    )
}

export default BlogSingle;