// import { combineReducers } from 'redux';


export function masterchefContract(state = {}, action) {
  switch (action.type) {
    case 'MASTERCHEF_CONTRACT_LOADED':
      return { ...state, loaded: true, masterchefContract: action.contract }
    default:
      return state
  }
}


// const cryptoCubbiesReducer = combineReducers({
//   cubbyNFTContract,
//   ccash,
//   cubbymarket
// })

// export default cryptoCubbiesReducer