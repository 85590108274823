import { combineReducers } from 'redux';

function usdcContract(state = {}, action) {
  switch (action.type) {
    case 'USDC_CONTRACT_LOADED':
      return { ...state, loaded: true, usdc: action.usdc }
    case 'USDC_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, usdcBal: action.usdcBal }
      case 'USDC_POOL_DATA_LOADED':
        return { ...state, loaded: true, usdcPoolData: action.usdcPoolData }
    default:
      return state
  }
}

function usdcUserinfo(state = {}, action) {
  switch (action.type) {
    case 'USDC_USER_INFO_LOADED':
      return { ...state, loaded: true, usdcUserInfo: action.usdcUserInfo }
    default:
      return state
  }
}

const usdcReducer = combineReducers({
  usdcContract,
  usdcUserinfo,
})

export default usdcReducer