import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import {
  masterchefContractLoaded
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadMasterChef = async (dispatch, web3, netId) => {
  try {
    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)
      dispatch(masterchefContractLoaded(masterchef))
      return masterchef
    } else {
      const masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)
      dispatch(masterchefContractLoaded(masterchef))
      return masterchef
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(masterchefContractLoaded(null))
    return null
  }
}