export function daiContractLoaded(dai) {
  return {
    type: 'DAI_CONTRACT_LOADED',
    dai
  }
}

export function daiMasterChefBalanceLoaded(daiBal) {
  return {
    type: 'DAI_MASTERCHEFBAL_LOADED',
    daiBal
  }
}

export function daiPoolDataLoaded(daiPoolData) {
  return {
    type: 'DAI_POOL_DATA_LOADED',
    daiPoolData
  }
}

export function daiUserInfoLoaded(daiUserInfo) {
  return {
    type: 'DAI_USER_INFO_LOADED',
    daiUserInfo
  }
}