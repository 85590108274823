import { updatePrice } from '../../store/cubby/interactions'
import React, { Component } from 'react';
import { connect } from 'react-redux'

// import {
//     metadataSelector,
//   } from '../../store/selectors'


  class CubbyUpdatePrice extends Component {

    render(props) {
      return (
        <div>
              <form
                  style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '1px',
                  }}
                  onSubmit={(event) => {
                  event.preventDefault();
                  updatePrice(this.props.dispatch, this.props.nftMarketID, this.sendAddress.value);
                  }}
              >
                  <input
                  style={{ width: '53%' }}
                  id='sendAddress'
                  type='text'
                  ref={(input) => (this.sendAddress = input)}
                  className='form-control'
                  placeholder='new price'
                  required
                  />
                  <button type='submit' className='btn btn-primary' style={{ width: '37%' }}>
                  update
                  </button>
              </form>
  
        </div>
      );
    }
  }

function mapStateToProps(state) {
  return {
    // metadata: metadataSelector(state),
  }
}

export default connect(mapStateToProps)(CubbyUpdatePrice)