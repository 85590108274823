import { createSelector } from 'reselect'
import { get } from 'lodash'


const daiContract = state => get(state, 'DeFiBankReducer.daiReducer.daiContract.dai')
export const daiContractSelector = createSelector(daiContract, c => c)

const daiMasterChefBal = state => get(state, 'DeFiBankReducer.daiReducer.daiContract.daiBal')
export const daiMasterChefBalSelector = createSelector(daiMasterChefBal, b => b)

const daiPoolData = state => get(state, 'DeFiBankReducer.daiReducer.daiContract.daiPoolData')
export const daiPoolDataSelector = createSelector(daiPoolData, p => p)

const daiUserinfo = state => get(state, 'DeFiBankReducer.daiReducer.daiUserinfo.daiUserInfo')
export const daiUserInfoSelector = createSelector(daiUserinfo, u => u)