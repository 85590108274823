export function cubbyNFTContractLoaded(contract) {
  return {
    type: 'CUBBY_CONTRACT_LOADED',
    contract
  }
}

export function metadataLoaded(metadata) {
  return {
    type: 'CUBBY_METADATA_LOADED',
    metadata
  }
}


export function cubbyNFTsLoaded(nfts) {
  return {
    type: 'CUBBY_NFTS_LOADED',
    nfts
  }
}

export function ccashDataLoaded(data) {
  return {
    type: 'CCASH_DATA_LOADED',
    data
  }
}

export function ccashApproveLoaded(approve) {
  return {
    type: 'CCASH_APPROVE_LOADED',
    approve
  }
}

export function cubbyMarketLoaded(market) {
  return {
    type: 'CUBBY_MARKET_LOADED',
    market
  }
}

export function cubbyMarketNFTsLoaded(listings) {
  return {
    type: 'CUBBYMARKET_NFTS_LOADED',
    listings
  }
}