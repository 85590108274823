import React from 'react'
import { Link } from 'react-router-dom';

const BlogCards = (props) => {
    return (
        <>
            <div className="col-12 col-md-4 item">
                {/* Single Blog */}
                <div className="card blog-card">
                    {/* Blog Thumb */}
                    <div className="blog-thumb">
                        <Link to={props.link}>
                            <img src={props.imgsrc} className="img-fluid" alt="Buy and Sell NFT" />
                        </Link>
                    </div>
                    {/* Blog Content */}
                    <div className="blog-content">
                        {/* Meta Info */}
                        <ul className="meta-info d-flex justify-content-between list-unstyled mt-4">
                            <li>
                                By <span>{props.author}</span>
                            </li>
                            <li>
                                <span>{props.date}</span>
                            </li>
                        </ul>
                        {/* Blog Title */}
                        <Link to={props.link}>
                            <h4 className="color-black">{props.title}</h4>
                        </Link>
                        <p>
                            {props.article}
                        </p>
                        {/* Blog Button */}
                        <Link className="btn content-btn" to={props.link}>
                            Read More
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogCards;