import React, { useState } from 'react'
import { connect } from 'react-redux'
import { approveWETH, depositWETH, withdrawWETH, wethUpdate } from '../../../store/bank/wrappedethereum/interactions'

// import {
//     accountSelector,
//   } from '../../../store/selectors'

import {
  wethMasterChefBalSelector,
  wethPoolDataSelector,
  wethUserInfoSelector,
} from '../../../store/bank/wrappedethereum/selectors'

import wethImg from '../../../images/dbank/ethereum-eth.svg'


const WETHPool = (props) => {

  const [withdrawAmount, setWithdrawAmount] = useState()
  const [depositAmount, setDepositAmount] = useState()

  return (


    <div>
      {props.mcBal === undefined || props.poolInfo === undefined || props.userInfo === undefined ? <p>loading</p> :

        <div className="">
          <div
            className='container-fluid mt-5 card'
            style={{
              // backgroundColor: '#16151a',
              color: '#000',
              borderRadius: '20px',
              padding: 24,
              display: 'flex',
              justifyContent: 'space-between',
              width: '325px',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 16,
                }}
              >
                <div style={{ width: 50, height: 50 }}> <img src={wethImg} alt='defi icon' className=" img-fluid" /></div>
                <h5 style={{ marginTop: 15, color: '#000', }} >Wrapped Ethereum</h5>
                <button className="btn btn-primary btn-sm"
                  style={{
                    height: '50%'
                  }}
                  onClick={(event) => {
                    wethUpdate(props.dispatch);
                  }}
                >
                  <i className="fas fa-sync"></i>
                </button>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: 16,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: 20,
                      color: "#4ee94f"
                    }}>
                    <div><b>APR</b></div>
                    <div>
                      ~{props.userInfo.depositAmount <= 0 ?
                        Math.round(
                          ((props.poolInfo.ccashPerBlock * props.poolInfo.poolInfo.allocPoint / props.poolInfo.totalAllocPoints)) * props.poolInfo.blocksPerYear) :
                        Math.round(
                          ((((props.userInfo.depositAmount) / props.mcBal[0].mcWETHBal)
                            * (((props.poolInfo.ccashPerBlock - (props.poolInfo.ccashPerBlock / 10)) * props.poolInfo.poolInfo.allocPoint / props.poolInfo.totalAllocPoints)))
                            * props.poolInfo.blocksPerYear)
                        )
                      } %
                    </div>
                  </div>
                </div>



                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 15,
                  }}
                >
                  <div>Deposit Fee</div>
                  <div>{props.poolInfo.poolInfo.depositFeeBP / 100} %</div>
                </div>
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{ textAlign: 'start', margin: 0 }}> CCASH Earned</p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {props.poolInfo.ccashPendingCWETH > 0 ? props.poolInfo.ccashPendingCWETH : 0}
                    <button className='btn btn-primary'
                      onClick={(event) => {
                        // let _poolID = 3
                        withdrawWETH(props.dispatch, 0);
                      }}
                    >claim</button>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'start', margin: 0 }}> {props.userInfo.depositAmount} WETH Staked</p>
                </div>
              </div>

              {props.mcBal[0].wethApproveBool ?
                <div
                  type='Success'
                  className='btn btn-block'
                  style={{
                    //   border: '1px ridge #00ff00',
                    color: '#00ff00',
                    marginTop: 16,
                    marginBottom: 16,
                  }}

                >
                  unlocked
                </div>

                :
                <button
                  type='Success'
                  className='btn btn-block'
                  style={{
                    border: '1px ridge #00ff00',
                    color: '#00ff00',
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                  onClick={(event) => {
                    approveWETH(props.dispatch);
                  }}
                >
                  <i className="fa fa-unlock mr-2" aria-hidden="true"></i>

                  Unlock Pool
                </button>

              }


              <form
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '16px',
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  // let _poolID = 3
                  depositWETH(props.dispatch, depositAmount);
                }}
              >
                <input
                  style={{ width: '60%' }}
                  value={depositAmount}
                  onChange={e => setDepositAmount(e.target.value)}
                  // id='depositamountWETH'
                  // type='text'
                  // ref={(input) => (depositamountWETH = input)}
                  // className='form-control'
                  placeholder='Deposit WETH'
                  required
                />
                <button
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',

                  }}
                  onClick={e => { setDepositAmount(props.mcBal[0].userWETHBal) }}
                >max</button>
                <button type='submit' className='btn btn-primary'>
                  Deposit
                </button>
              </form>

              <form
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  // let _poolID = 3
                  withdrawWETH(props.dispatch, withdrawAmount);
                }}
              >
                <input
                  style={{ width: '60%' }}
                  value={withdrawAmount}
                  onChange={e => setWithdrawAmount(e.target.value)}
                  // id='withdrawamountWETH'
                  // type='text'
                  // ref={(input) => (withdrawamountWETH = input)}
                  // className='form-control'
                  placeholder='Withdraw WETH'
                  required
                />
                <button
                  style={{
                    backgroundColor: 'transparent',
                    marginTop: 16,
                    marginBottom: 16,
                    color: '#000',
                  }}
                  onClick={e => { setWithdrawAmount(props.userInfo.depositAmount) }}
                >max</button>
                <button type='submit' className='btn btn-primary'>
                  Withdraw
                </button>
              </form>
              <div style={{ marginTop: 16 }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>Total WETH Locked:</div>
                  <div>{props.mcBal[0].mcWETHBal} </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      }
    </div>


  )
}

function mapStateToProps(state) {
  return {
    //   account: accountSelector(state),
    mcBal: wethMasterChefBalSelector(state),
    poolInfo: wethPoolDataSelector(state),
    userInfo: wethUserInfoSelector(state),
  }
}

export default connect(mapStateToProps)(WETHPool);



