import React from 'react';

import CSTPool from './pools/CSTPool'
import CCASHPool from './pools/CCASHPool'
import WMATICPool from './pools/WMATICPool'
import WETHPool from './pools/WETHPool'
import WBTCPool from './pools/WBTCPool'
import QUICKPool from './pools/QUICKPool'
import USDCPool from './pools/USDCPool'
import USDTPool from './pools/USDTPool'
import DAIPool from './pools/DAIPool'

const MainV2 = () => (


  <div
    className='yield-farm'
    style={{ color: '#f8f8ff', margin: '10px', padding: '5px' }}
  >
    <div className="cubbybox-container">

      <CSTPool />
      <CCASHPool />
      <WMATICPool />
      <WETHPool />
      <WBTCPool />
      <QUICKPool />
      <USDCPool />
      <USDTPool />
      <DAIPool />

    </div>


  </div>
);

export default MainV2;