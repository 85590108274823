import React, { useState } from 'react'

const DBankHelp = () => {
    const [showHelp, setShowHelp] = useState(false)
    const handleClick = () => setShowHelp(!showHelp);


    return (
        <div onClick={handleClick} className="pt-3 text-center">
            {!showHelp ? <p>Need help? <span className="link3"><a>Click Here!</a></span></p> :
                <div>
                    <p>
                        The simplest way to view this DeFi / GameFi app is as a savings account.
                        You deposit any crpto/token listed below and get a ROI paid out in Coinopolis Cash.
                        The interest rates, seen below, reflects the total interest rate for that pool.
                        Rewards are paid out in Coinopolis Cash (this is the way that Coinopolis Cash gets minted into circulation).
                    </p>
                    <p>
                        Tips: When interacting with the blockchain (e.g. when you click withdraw, deposit, or approve), you will need to wait until your
                        transaction gets confirmed by the blockchain. Once confirmed, if the pool does not auto-update, click on the refresh button for the pool.
                        This will re-load the pool data.
                    </p>
                    <p>
                        pro tip: if you have a pending transaction, do not initiate a new transaction until the previous transaction clears.
                        Otherwise you will be stuck with a messy transaction Q that we would rather not need to assist ya with! :D
                    </p>
                </div>
            }
        </div>
    )
}

export default DBankHelp;


// import Account from '../Account'

// const Navbar2 = () =>  {

//   const [click, setClick] = useState(false);
//   const [dropdown, setDropdown] = useState(false);

//   const handleClick = () => setClick(!click);
//   const closeMobileMenu = () => setClick(false);

//   const onMouseEnter = () => {
//     if (window.innerWidth < 900) {
//       setDropdown(true);
//       console.log(dropdown)

//     } else {
//       setDropdown(false);
//       console.log(dropdown)

//     }
//   };

//   const onMouseLeave = () => {
//       setDropdown(true);
//       console.log(dropdown)
//   };

// return (
//   <>
//       <nav className="navbar navbar-expand-lg rounded-bottom navBorderBottom" style={{ color: "#ffffff", fontSize: '20px', "backgroundColor": "#363636" }}>



//         <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
//             <b>coinopolis</b>
//         </Link>
//         <div className='menu-icon' onClick={handleClick}>
//           <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
//         </div>
//         <ul className={click ? 'nav-menu active' : 'nav-menu'}>
//           <li className='nav-item'>
//             <Link to='/' className='nav-links' onClick={closeMobileMenu}></Link>