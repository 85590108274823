// import { nftsData } from '../backEnd/scripts/nftsData.js'
import CubbiesContract from '../../backEnd/abis/mainnet/CryptoCubbies.json'
import CubbyMarket from '../../backEnd/abis/mainnet/CryptoCubbiesMarket.json'
import CCASH from '../../backEnd/abis/ERC20/masterchef/CCASH.json'
import Web3 from 'web3'

import { CCASH_ADDRESS, CUBBIES_ADDRESS, CUBBIESMARKET_ADDRESS } from '../config.json'
import { ethers } from 'ethers'

import { update, loadProvider, loadNetwork, loadAccount } from '../interactions'

import {
  metadataLoaded,
  ccashDataLoaded,
  cubbyNFTContractLoaded,
  ccashApproveLoaded,
  cubbyMarketLoaded,
  cubbyNFTsLoaded,
  cubbyMarketNFTsLoaded
} from './actions'


export const loadContract = async (dispatch, provider) => {
  try {
    const cubbies = new ethers.Contract(CUBBIES_ADDRESS, CubbiesContract.abi, provider)
    dispatch(cubbyNFTContractLoaded(cubbies))
    return cubbies
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load contract: ', e)
    dispatch(cubbyNFTContractLoaded(null))
    return null
  }
}

export const loadCubbyMarket = async (dispatch, provider) => {
  try {
    const cubbymarket = new ethers.Contract(CUBBIESMARKET_ADDRESS, CubbyMarket.abi, provider)
    dispatch(cubbyMarketLoaded(cubbymarket))
    return cubbymarket
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load cubbymarket: ', e)
    dispatch(cubbyMarketLoaded(null))
    return null
  }
}

export const loadCcash = async (dispatch, provider) => {
  try {
    const ccash = new ethers.Contract(CCASH_ADDRESS, CCASH.abi, provider)
    dispatch(ccashDataLoaded(ccash))
    return ccash
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load ccash: ', e)
    dispatch(ccashDataLoaded(null))
    return null
  }
}

export const cubbyUpdate = async (dispatch) => {
  try{
    let account, provider, netId, contract, ccash, cubbymarket, cubbymarketAddy, cubbyNFTAddy
    await update(dispatch)

    provider = await loadProvider(dispatch)
    account = await loadAccount(dispatch)
    const { chainId } = await provider.getNetwork()
    if(chainId === 137) {
      contract = await loadContract(dispatch, provider,)
      await loadMintFeeData(dispatch, contract, account, CUBBIESMARKET_ADDRESS)
      ccash = await loadCcash(dispatch, provider, netId)
      cubbymarket = await loadCubbyMarket(dispatch, provider)
      // await loadNftData(dispatch, contract)
      await loadCcashData(dispatch, ccash, account, CUBBIESMARKET_ADDRESS, CUBBIES_ADDRESS)
      await fetchMarketNFTs(dispatch, cubbymarket)
      await fetchCubbyNFTs(dispatch, contract)
    } else {
      let url = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      provider = await new ethers.JsonRpcProvider(url)
      ccash = await loadCcash(dispatch, provider)
      cubbymarket = await loadCubbyMarket(dispatch, provider)
      await loadCcashData(dispatch, ccash, account, CUBBIESMARKET_ADDRESS, CUBBIES_ADDRESS)
      await fetchMarketNFTs(dispatch, cubbymarket)
    }

  } catch (e) {
    console.log('Error, update data: ', e)
  }
}



//get mint fee data from

export const loadMintFeeData = async (dispatch, contract, account, cubbyMarketAddy) => {
  try{
    const contractData = {}
    const totalSupply = await contract.totalSupply()
    contractData.totalSupply = totalSupply

    const mintNumber = await contract.mintNumber()
    contractData.mintNumber = mintNumber

    const maticMintFee = await contract.maticMintFee()
    const maticFee = maticMintFee / 10**18
    contractData.maticFee = maticFee

    const ccashMintFee = await contract.ccashMintFee()
    const ccashFee = ccashMintFee / 10**18
    contractData.ccashFee = ccashFee

    const publicMintingActive = await contract.publicMintingActive()
    contractData.publicMintingActive = publicMintingActive

    const isApprovedForAll = await contract.isApprovedForAll(account, cubbyMarketAddy)
    contractData.isApprovedForAll = isApprovedForAll

    console.log("metadata")
    console.log(contractData)



    dispatch(metadataLoaded(contractData))
  } catch (e) {
    console.log('Error, load images', e)
  }
}

//get ccash data
export const loadCcashData = async (dispatch, ccash, account, cubbymarketAddy, cubbyNFTAddy) => {
  try{
    const preBalance = await ccash.balanceOf(account)

    const balance = Number(preBalance) / 10**18

  
    let market_allowanceBool, nft_allowanceBool

    let market_preAllowance = await ccash.allowance(account, cubbymarketAddy)
    let market_allowance = Number(market_preAllowance) / 10**18

    if(market_allowance > 10000) {
      market_allowanceBool = true
    } else {
      market_allowanceBool = false;
    }

    let nft_preAllowance = await ccash.allowance(account, cubbyNFTAddy)
    let nft_allowance = Number(nft_preAllowance) / 10**18


    if(nft_allowance > 100000000) {
      nft_allowanceBool = true
    } else {
      nft_allowanceBool = false;
    }

    let ccashInfo = {
      nft_allowanceBool,
      market_allowanceBool,
      balance
    }

    dispatch(ccashApproveLoaded(ccashInfo))
  } catch (e) {
    console.log('Error, load CCASH data', e)
  }
}

export const fetchMarketNFTs = async (dispatch, cubbymarket) => {
  try{
    let ccashFee, marketListingData
    if(cubbymarket === undefined) {
      console.log("UNDEFINED")
      let url = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      let provider = new ethers.JsonRpcProvider(url)
      cubbymarket = new ethers.Contract(CUBBIESMARKET_ADDRESS, CubbyMarket.abi, provider)
      let newNum = await cubbymarket.ccashFee()
      ccashFee = Number(newNum) / 10**18
      marketListingData = await cubbymarket.fetchMarketNFTs()
    } else {
      let newNum = await cubbymarket.ccashFee()
      ccashFee = Number(newNum) / 10**18
      marketListingData = await cubbymarket.fetchMarketNFTs()
    }


    let webSiteMarketData = []

    for(let i = 0; i < marketListingData.length; i++) {
      webSiteMarketData[i] = {
        nftMarketID: marketListingData[i].nftMarketID,
        tokenID: marketListingData[i].tokenID,
        ccashFee,
        price: marketListingData[i].price,
        listed: marketListingData[i].listed,
        owner: marketListingData[i].owner,
        tokenURI: marketListingData[i].tokenURI
      }
      let tokenURI = marketListingData[i].tokenURI
      if(tokenURI !== '') {
        const response = await fetch(tokenURI)
        const itemData = await response.json()
        webSiteMarketData[i].tokenURIData = itemData;
      } else {
        webSiteMarketData[i].tokenURI = 'no uri';
      }
      
    }

    dispatch(cubbyMarketNFTsLoaded(webSiteMarketData))
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const fetchCubbyNFTs = async (dispatch, contract) => {
  try{
    const allTokenData = await contract.fetchAllTokens()
    let websiteAllTokenData = []

    for(let i = 0; i < allTokenData.length; i++) {
      websiteAllTokenData[i] = {
        tokenID: allTokenData[i].id,
        tokenURI: allTokenData[i].uri,
        ownerOf: allTokenData[i].ownerOf,
      }
      let _tokenURI = allTokenData[i].uri
      if(_tokenURI !== '') {
        const response = await fetch(_tokenURI)
        const itemData = await response.json()
        websiteAllTokenData[i].tokenURIData = itemData;
      } else {
        websiteAllTokenData[i].tokenURI = 'no uri';
      }
      
    }
    dispatch(cubbyNFTsLoaded(websiteAllTokenData))
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}


export const approveMarket = async (dispatch) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    // const netId = await web3.eth.net.getId()
    const ccash = await loadCcash(dispatch, provider)

    let amount = 10000000000
    let newAmount =  ethers.parseUnits(amount.toString(), 18)

    const signer = await provider.getSigner()

    await ccash.connect(signer).approve(CUBBIESMARKET_ADDRESS, newAmount)
      .on('receipt', async (r) => {
        cubbyUpdate(dispatch)
        // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
  } catch (e){
    console.log('Error, approve market', e)
  }
  
}

export const approveNFT = async (dispatch) => {
    try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    console.log(account)
    // const netId = await web3.eth.net.getId()
    const ccash = await loadCcash(dispatch, provider)

    let amount = 100000000000000
    let newAmount =  ethers.parseUnits(amount.toString(), 18)

    const signer = await provider.getSigner()
    await ccash.connect(signer).approve(CUBBIES_ADDRESS, newAmount)
      .on('receipt', async (r) => {
        cubbyUpdate(dispatch)
        // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
  } catch (e){
    console.log('Error, apporve contract', e)
  }
  
}



export const mintNFT = async (dispatch, price) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    const contract = await loadContract(dispatch, provider)

    const signer = await provider.getSigner()
    await contract.connect(signer).publicMint({from: account, value: price})
      .on('receipt', async (r) => {
        cubbyUpdate(dispatch)
        // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
  } catch (e){
    console.log('Error, buy NFT', e)
  }
  
}

export const purchaseNFT = async (dispatch, nftMarketID, price) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    const cubbymarket = await loadCubbyMarket(dispatch)

    const signer = await provider.getSigner()
    await cubbymarket.connect(signer).purchaseNFT(nftMarketID, {from: account, value: price})
      .on('receipt', async (r) => {
        cubbyUpdate(dispatch)
        // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
  } catch (e){
    console.log('Error, buy NFT', e)
  }
  
}

export const listNFT = async (dispatch, nftMarketID, price) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    const cubbymarket = await loadCubbyMarket(dispatch)
    let _nftData = await cubbymarket.nft(nftMarketID)
    let tokenListed = _nftData.owner
    let newAmount =  ethers.parseUnits(price.toString(), 18)
    console.log(tokenListed)
    console.log(newAmount)

    const signer = await provider.getSigner()

    if(tokenListed === 0x0000000000000000000000000000000000000000) {
      console.log('creating')
      await cubbymarket.connect(signer).createNFT(nftMarketID, newAmount)
      .on('receipt', async (r) => {
        cubbyUpdate(dispatch)
        // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
    } else {
      console.log('relisting')

      await cubbymarket.connect(signer).relistNFT(nftMarketID, newAmount)
      .on('receipt', async (r) => {
        cubbyUpdate(dispatch)
        // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
    }

  } catch (e){
    console.log('Error, buy NFT', e)
  }
}

export const safeTransferFrom = async (dispatch, addressTo, tokenID) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    const contract = await loadContract(dispatch)

    const signer = await provider.getSigner()
    await contract.connect(signer).safeTransferFrom(account, addressTo, tokenID)
    .on('receipt', async (r) => {
      cubbyUpdate(dispatch)
      // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
} catch (e){
    console.log('Error, buy NFT', e)
  }
}


export const setApprovalForAll = async (dispatch) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)    

    const contract = await loadContract(dispatch, provider)

    const signer = await provider.getSigner()
    await contract.connect(signer).setApprovalForAll(CUBBIESMARKET_ADDRESS, true)
    .on('receipt', async (r) => {
      cubbyUpdate(dispatch)
      // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
} catch (e){
    console.log('Error, buy NFT', e)
  }
}

export const removeNFT = async (dispatch, nftMarketID) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    const cubbymarket = await loadCubbyMarket(dispatch, provider)
    // let tokenExist = await cubbymarket.methods._tokenExists(nftMarketID).call()

    const signer = await provider.getSigner()
    await cubbymarket.connect(signer).removeToken(nftMarketID)
    .on('receipt', async (r) => {
      cubbyUpdate(dispatch)
      // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
  } catch (e){
    console.log('Error, buy NFT', e)
  }
}

export const updatePrice = async (dispatch, nftMarketID, price) => {
  try{
    const provider = await loadProvider(dispatch)
    await loadNetwork(dispatch)
    const account = await loadAccount(dispatch)
    const cubbymarket = await loadCubbyMarket(dispatch, provider)

    let newAmount =  ethers.parseUnits(price.toString(), 18)

    const signer = await provider.getSigner()
    await cubbymarket.connect(signer).updatePrice(nftMarketID, newAmount)
    .on('receipt', async (r) => {
      cubbyUpdate(dispatch)
      // window.alert(`Congratulations, you've received NFT with ID: ${id}\nAddress: ${Contract.networks[netId].address}`)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
  } catch (e){
    console.log('Error, buy NFT', e)
  }
}
