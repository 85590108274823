export function wbtcContractLoaded(wbtc) {
  return {
    type: 'WBTC_CONTRACT_LOADED',
    wbtc
  }
}

export function wbtcMasterChefBalanceLoaded(wbtcBal) {
  return {
    type: 'WBTC_MASTERCHEFBAL_LOADED',
    wbtcBal
  }
}

export function wbtcPoolDataLoaded(wbtcPoolData) {
  return {
    type: 'WBTC_POOL_DATA_LOADED',
    wbtcPoolData
  }
}

export function wbtcUserInfoLoaded(wbtcUserInfo) {
  return {
    type: 'WBTC_USER_INFO_LOADED',
    wbtcUserInfo
  }
}