import Web3 from 'web3'

import { update, loadWeb3, loadBalance, loadNetwork, loadAccount } from '../interactions'

import {

} from './actions'


export const loadWeb3Bank = async (dispatch) => {
    let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
    const web3 = new Web3(provider)
    return web3
  }
  