import { createSelector } from 'reselect'
import { get } from 'lodash'


const usdtContract = state => get(state, 'DeFiBankReducer.usdtReducer.usdtContract.usdt')
export const usdtContractSelector = createSelector(usdtContract, c => c)

const usdtMasterChefBal = state => get(state, 'DeFiBankReducer.usdtReducer.usdtContract.usdtBal')
export const usdtMasterChefBalSelector = createSelector(usdtMasterChefBal, b => b)

const usdtPoolData = state => get(state, 'DeFiBankReducer.usdtReducer.usdtContract.usdtPoolData')
export const usdtPoolDataSelector = createSelector(usdtPoolData, p => p)

const usdtUserinfo = state => get(state, 'DeFiBankReducer.usdtReducer.usdtUserinfo.usdtUserInfo')
export const usdtUserInfoSelector = createSelector(usdtUserinfo, u => u)