import CST from '../../../backEnd/abis/ERC20/masterchef/CST.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  cstContractLoaded,
  cstUserInfoLoaded,
  cstMasterChefBalanceLoaded,
  cstPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadCST = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const cst = new web3.eth.Contract(CST.abi, CST.networks[netId].address)
      dispatch(cstContractLoaded(cst))
      return cst
    } else {
      const cst = new web3.eth.Contract(CST.abi, CST.networks[netId].address)
      dispatch(cstContractLoaded(cst))
      return cst
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(cstContractLoaded(null))
    return null
  }
}



export const cstUpdate = async (dispatch) => {
  try{
    let account, web3, netId, cst, masterchef, masterChefAddy
    let poolID = 0

    await update(dispatch)

    web3 = await loadWeb3Bank(dispatch)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()

    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      cst = new web3.eth.Contract(CST.abi, CST.networks[netId].address)
      masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)
      masterChefAddy = MasterChef.networks[netId].address
  
      cst = await loadCST(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
      await getCSTBalance(dispatch, cst, masterChefAddy, account)
      await fetchCSTPoolData(dispatch, poolID, masterchef)
      await CSTUserInfo(dispatch, account, poolID, masterchef)
    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      cst = await loadCST(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
  
      await getCSTBalance(dispatch, cst, masterChefAddy, account)
      await fetchCSTPoolData(dispatch, poolID, masterchef)
      await CSTUserInfo(dispatch, account, poolID, masterchef)
    }

  } catch (e) {
    console.log('Error, update data: ', e)
  }
}



export const getCSTBalance = async (dispatch, cst, masterChefAddy, account) => {
  try{
    let mcCSTBal, userCSTBal, allowance
    let cstBalance = []
    let cstApproveBool = false
    mcCSTBal = await cst.methods.balanceOf(masterChefAddy).call() / 10**18
    console.log(account)
    if(account !== null) {
      userCSTBal = await cst.methods.balanceOf(account).call() / 10**18
      console.log(userCSTBal)
      allowance = await cst.methods.allowance(account, masterChefAddy).call()  / 10**18

      console.log(allowance)
      if(allowance >= userCSTBal) {
        cstApproveBool = true
      }
    } else {
      userCSTBal = 0
    }

    cstBalance[0] = {
      mcCSTBal,
      userCSTBal,
      cstApproveBool
    }

    dispatch(cstMasterChefBalanceLoaded(cstBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}



export const fetchCSTPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let cstPoolInfo
    // CST poolID = 0
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    cstPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }
    dispatch(cstPoolDataLoaded(cstPoolInfo))
    console.log(cstPoolInfo)
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const CSTUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let cstUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _cstPendingCCash = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let cstPendingCCCASH = _cstPendingCCash / 10**18
      cstUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**18,
        cstPendingCCCASH
      }
    } else {
      cstUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        cstPendingCCCASH: 0
      }
    }
    dispatch(cstUserInfoLoaded(cstUserInfo))
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const approveCST = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let cst = await loadCST(dispatch, web3, netId)
  try {
    let amount = 1000000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await cst.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        cstUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositCST = async (dispatch, amount) => {
  console.log(amount)
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 0
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        cstUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawCST = async (dispatch, amount) => {
  console.log(amount)

  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 0
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        cstUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

