import React from 'react';

const HeaderNftCard = () => {

    return (
        <div className="header-card">
            <a href="https://opensea.io/assets/cryptocubbies" target="_blank" className="card">
                <div className="card__body">
                    <div className="card__top">
                        <div className="card__owner">
                            <div className="card__avatar" />
                            <div className="card__user">
                                <span className="card__user__title">Owned by</span>
                                <span className="card__user__code">CryptoCubbies</span>
                            </div>
                        </div>
                    </div>
                    <div className="card__image">
                        <img src="https://ipfs.io/ipfs/QmZvYM3UGLoBxuLfqrhox63bzeZrY7EoK5LD1JqsGnNzXP" className="img-fluid" alt="Buy NFT Crypt" />
                    </div>
                </div>
            </a>
        </div>

    )
}


export default HeaderNftCard