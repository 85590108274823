import React, { Component } from 'react'

import { connect } from 'react-redux'

import { update } from '../../store/interactions'
import { cstUpdate } from '../../store/bank/cst/interactions'
import { ccashUpdate } from '../../store/bank/ccash/interactions'
import { wmaticUpdate } from '../../store/bank/wrappedmatic/interactions'
import { wethUpdate } from '../../store/bank/wrappedethereum/interactions'
import { wbtcUpdate } from '../../store/bank/wrappedbitcoin/interactions'
import { quickUpdate } from '../../store/bank/quick/interactions'
import { usdcUpdate } from '../../store/bank/usdc/interactions'
import { usdtUpdate } from '../../store/bank/usdt/interactions'
import { daiUpdate } from '../../store/bank/dai/interactions'

import DBankHelp from './DBankHelp'
import MainV2 from './MainV2'
import Navbar3 from '../Navbar/Navbar3'


import telegramIcon from '../../images/telegram.svg'
import twitterIcon from '../../images/twitter.svg'
import instagramIcon from '../../images/instagram.svg'
import Breadcrumb from '../spaceodyssey/BreadcrumbSO'
import FooterNew from '../FooterNew'




class dBankAppV2 extends Component {

  async UNSAFE_componentWillMount() {
    await this.loadBlockchainData(this.props.dispatch)
  }

  async loadBlockchainData(dispatch) {
    /* Case 1, User connect for 1st time */
    if (typeof window.ethereum !== 'undefined') {
      await update(dispatch)
      await cstUpdate(dispatch)
      await ccashUpdate(dispatch)
      await wmaticUpdate(dispatch)
      await wethUpdate(dispatch)
      await wbtcUpdate(dispatch)
      await quickUpdate(dispatch)
      await usdcUpdate(dispatch)
      await usdtUpdate(dispatch)
      await daiUpdate(dispatch)
      /* Case 2 - User switch account */
      window.ethereum.on('accountsChanged', async () => {
        await update(dispatch)
        await cstUpdate(dispatch)
        await ccashUpdate(dispatch)
        await wmaticUpdate(dispatch)
        await wethUpdate(dispatch)
        await wbtcUpdate(dispatch)
        await quickUpdate(dispatch)
        await usdcUpdate(dispatch)
        await usdtUpdate(dispatch)
        await daiUpdate(dispatch)
      });
      /* Case 3 - User switch network */
      window.ethereum.on('chainChanged', async () => {
        await update(dispatch)
        await cstUpdate(dispatch)
        await ccashUpdate(dispatch)
        await wmaticUpdate(dispatch)
        await wethUpdate(dispatch)
        await wbtcUpdate(dispatch)
        await quickUpdate(dispatch)
        await usdcUpdate(dispatch)
        await usdtUpdate(dispatch)
        await daiUpdate(dispatch)
      });
    }
  }


  render() {
    return (
      // <ApolloProvider client={client} >
      <>
        <Navbar3 />
        <Breadcrumb name="Farm" />
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-7 ">
              {/* Intro */}
              <div className="intro text-center">
                <div className="intro-content">
                  <span>Farming</span>
                  <h3 className="mt-3 mb-0">Farming site has been paused and you will not be able to make any deposits.</h3>
                  <h5>Deposit fees have been increased.</h5>

                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-12 col-lg-12 text-center">
              <p >Where do your deposit fees go? great question, thank you for asking.</p>

              <p>50% of fees will go towards $CCASH buybacks (increasing your ROI)</p>
              <p>25% of fees will go towards $CST buybacks and providing liquidity for CST and CCASH</p>
              <p>25% will go towards developments of the Coinopolis Metaverse</p>
              <div className="pt-3">
                <a href='https://quickswap.exchange/#/swap?inputCurrency=0x04d40bEFB0a3DFbF76c1B1157EB23865Abdb6D0B' target='blank' rel="noopener noreferrer">
                  <button className='btn btn-bordered-white'>Buy $CST</button></a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <a href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank' rel="noopener noreferrer">
                  <button className='btn btn-bordered-white'>Buy $CCASH</button></a>
              </div>
              <div>
              </div>

            </div>
            <DBankHelp />

            <MainV2 />
          </div>



          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                <p>MasterChef deployed at:&nbsp;</p>
                <a
                  href={`https://polygonscan.com/address/0x7518167AACa8E7Ede78015D19Ed02169E62796F9`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#352095" }}
                >
                  0x7518167AACa8E7Ede78015D19Ed02169E62796F9
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterNew />
      </>
      // </ApolloProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(dBankAppV2);