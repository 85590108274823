import { combineReducers } from 'redux';

function wbtcContract(state = {}, action) {
  switch (action.type) {
    case 'WBTC_CONTRACT_LOADED':
      return { ...state, loaded: true, wbtc: action.wbtc }
    case 'WBTC_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, wbtcBal: action.wbtcBal }
      case 'WBTC_POOL_DATA_LOADED':
        return { ...state, loaded: true, wbtcPoolData: action.wbtcPoolData }
    default:
      return state
  }
}

function wbtcUserinfo(state = {}, action) {
  switch (action.type) {
    case 'WBTC_USER_INFO_LOADED':
      return { ...state, loaded: true, wbtcUserInfo: action.wbtcUserInfo }
    default:
      return state
  }
}

const wbtcReducer = combineReducers({
  wbtcContract,
  wbtcUserinfo,
})

export default wbtcReducer