import { mintNFT, approveNFT } from '../../store/cubby/interactions'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CubbyLoading from './CubbyLoading'


import {
  accountSelector,
} from '../../store/selectors'

import {
  metadataSelector,
  ccashApproveSelector,
} from '../../store/cubby/selectors'

import backgroundImg from '../../images/cubbies2.svg'

// import ParticleBG from '../particles/ParticleBG'


import CubbyMarket from './CubbyMarket'
import CubbyWallet from './CubbyWallet'

import Footer from '../Footer'
import FooterNew from '../FooterNew'



class CubbyMain extends Component {

  render(props) {
    try {
      return (
        <>
          <div>
            <div className="main"
              style={{
                backgroundImage: `url(${backgroundImg})`,
                backgroundPosition: 'justify-flex',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                // backgroundSize: 'auto',
                // width: '100%',
                // height: '100%'
              }}>

              {/* <ParticleBG /> */}



              <div style={{
                backgroundColor: '#121117',
              }}>
                {/* {CubbyInfo()} */}
                {this.props.metadata === undefined ? <p>loading</p> : <p></p>}


                <div className='container text-center'>



                  <div style={{ padding: '10px' }}>

                    <h2>Weekly Mintables!</h2>
                    <h4>Mint or Buy below!</h4>


                    <div className='mintingInfo-container'>
                      <h4 className="chubby-color ">Max Suppy: 789</h4>
                      <h4 className="chubby-color ">Current Supply: {this.props.metadata.totalSupply}</h4>
                    </div>

                    <div className='mintingInfo-container'>
                      <h5 >Current mint fees: </h5>
                    </div>

                    <div className='mintingInfo-container'>
                      <h5>{this.props.metadata.maticFee} MATIC and {this.props.metadata.ccashFee} CCASH</h5>
                    </div>


                  </div>

                </div>

                {this.props.metadata.mintNumber > this.props.metadata.totalSupply || this.props.metadata.publicMintingActive ?


                  <div className='container'>

                    <div>

                      <h4 style={{ color: '#55FF55' }}>MINTING LIVE!</h4>
                      <h5>available to mint: {this.props.metadata.mintNumber - this.props.metadata.totalSupply}</h5>


                      {this.props.ccashApprove.nft_allowanceBool ?
                        <div className='mintingInfo-container'>

                          <button
                            type="Success"
                            className='btn btn-primary btn-bordered-white'
                            style={{ width: '200px' }}
                            onClick={(e) => mintNFT(this.props.dispatch, this.props.metadata.maticFee * 10 ** 18)}

                          >
                            <b>m i n t</b>
                          </button>
                        </div>

                        :

                        <div className='mintingInfo-container'>

                          <button
                            type="Success"
                            className='btn btn-primary btn-bordered-white'
                            style={{ width: '200px', margin: '10px' }}
                            onClick={(e) => approveNFT(this.props.dispatch)}

                          >
                            <b>u n l o c k </b>
                          </button>
                        </div>

                      }
                    </div>

                  </div>

                  :

                  <div className='container'>

                    <div className="text-center">

                      <h5>Minting completed for now. </h5>


                      <div className='chubby-color pb-2'>
                        <p> View the marketplace below! </p>
                      </div>
                    </div>
                  </div>
                }
              </div>


              <div>
                <CubbyMarket />
                <CubbyWallet />
                <Footer />

                <FooterNew />
              </div>


            </div>

          </div>
        </>
      )
    } catch (e) {
      return (
        <CubbyLoading />
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    metadata: metadataSelector(state),
    ccashApprove: ccashApproveSelector(state),
  }
}

export default connect(mapStateToProps)(CubbyMain)