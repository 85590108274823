import React, { Component } from 'react';

class HowToWork extends Component {
    render() {
        return (
            <section className="work-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro mb-4">
                                <div className="intro-content">
                                    <span>How It Works</span>
                                    <h3 className="mt-3 mb-0">Create and sell your NFTs with Coinopolis</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <h4>Set up your wallet</h4>
                                <p>
                                    {/* Once you’ve set up your wallet of choice, connect it to OpenSea by
                                    clicking the NFT Marketplace in the top right corner. Learn about
                                    the wallets we support. */}
                                    Once your wallet is set up, connect it to Coinopolis by clicking on Login in the upper right corner.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <h4>Browse Collections</h4>
                                <p>
                                    Browse the collections and find an NFT that is right for you. &amp;
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <h4>Add your NFTs</h4>
                                <p>
                                    Email Info@coinopolis.io for information about your own marketplace at Coinopolis!
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 item">
                            {/* Single Work */}
                            <div className="single-work">
                                <h4>Listings on Coinopolis</h4>
                                <p>
                                    Currently working on adding different auction styles to Coinopolis, so stay tuned!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HowToWork;