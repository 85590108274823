// import { combineReducers } from 'redux';

export function cubbyNFTContract(state = {}, action) {
  switch (action.type) {
    case 'CUBBY_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'CUBBY_METADATA_LOADED':
      return { ...state, loaded: true, metadata: action.metadata }
    case 'CUBBY_NFTS_LOADED':
      return { ...state, loaded: true, nfts: action.nfts }
    default:
      return state
  }
}

export function ccash(state = {}, action) {
  switch (action.type) {
    case 'CCASH_DATA_LOADED':
      return { ...state, loaded: true, data: action.data }
    // case 'CCASH_BALANCE_LOADED':
    //   return { ...state, loaded: true, balance: action.balance }
      case 'CCASH_APPROVE_LOADED':
        return { ...state, loaded: true, approve: action.approve }
    default:
      return state
  }
}

export function cubbymarket(state = {}, action) {
  switch (action.type) {
    case 'CUBBY_MARKET_LOADED':
      return { ...state, loaded: true, market: action.market }
    case 'CUBBYMARKET_NFTS_LOADED':
      return { ...state, loaded: true, listings: action.listings }
    default:
      return state
  }
}


// const cryptoCubbiesReducer = combineReducers({
//   cubbyNFTContract,
//   ccash,
//   cubbymarket
// })

// export default cryptoCubbiesReducer