import { ethers } from 'ethers'
import CCASH from '../backEnd/abis/ERC20/masterchef/CCASH.json'
import { CCASH_ADDRESS } from './config.json'

import {
  web3Loaded as providerLoaded,
  web3NetworkLoaded,
  web3AccountLoaded,
  web3BalanceLoaded,
  ccashBalLoaded,
} from './actions'

export const loadProvider = async (dispatch) => {
  try{
    if(typeof window.ethereum !== 'undefined') {
      window.ethereum.autoRefreshOnNetworkChange = false;
      const provider = new ethers.BrowserProvider(window.ethereum);
      dispatch(providerLoaded(provider))
      return provider
    } else {
      const provider = new ethers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297")
      dispatch(providerLoaded(provider))
      return provider
    }
  } catch (e) {
    console.log('Error, load Web3: ', e)
  }
}

export const loadNetwork = async (dispatch) => {
  try{
    let provider
    // if(window.ethereum !== null) provider = new ethers.BrowserProvider(window.ethereum)
    
    // else provider = new ethers.JsonRpcProvider("http://127.0.0.1:8545/")
    // provider = new ethers.JsonRpcProvider("https://polygon-mumbai.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297")
    provider = new ethers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297")
    // else provider = new ethers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297")

    const { name } = await provider.getNetwork()
    const { chainId } = await provider.getNetwork()
    let network = name
    console.log(chainId)
    console.log(name)
    console.log(network)
    network = network.charAt(0).toUpperCase()+network.slice(1)
    dispatch(web3NetworkLoaded(network))
    return network
  } catch (e) {
    dispatch(web3NetworkLoaded('Wrong network'))
    console.log('Error, load network: ', e)
  }
}

export const loadAccount = async (dispatch) => {
  try{

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const account = ethers.getAddress(accounts[0])

    console.log(typeof(accounts))
    if(typeof account !== 'undefined'){
      dispatch(web3AccountLoaded(account))
      return account
    } else {
      dispatch(web3AccountLoaded(null))
      return null
    }
  } catch (e) {
    console.log('Error, load account: ', e)
  }
}

export const loadBalance = async (dispatch, provider, account) => {
  try {
    const etherBalance = await provider.getBalance(account)
    dispatch(web3BalanceLoaded((Number(etherBalance)/10**18).toFixed(5)))
  } catch (e) {
    console.log('Error, load balance: ', e)
  }
}

//get ccash data
export const loadCcashBal = async (dispatch, ccash, account) => {
  try{
    const preBalance = await ccash.balanceOf(account)
    const balance = Number(preBalance) / 10**18

    dispatch(ccashBalLoaded(balance))
  } catch (e) {
    console.log('Error, load ccash bal', e)
  }
}


export const update = async (dispatch) => {
  try{
    let account, provider, netId

    // provider = await loadProvider(dispatch)

    // provider = new ethers.JsonRpcProvider("https://polygon-mumbai.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297")
    provider = new ethers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297")
    await loadNetwork(dispatch)
    account = await loadAccount(dispatch)
    const { chainId } = await provider.getNetwork()
    netId = chainId

    const ccash = new ethers.Contract(CCASH_ADDRESS, CCASH.abi, provider)

    if(account){
      await loadBalance(dispatch, provider, account)
      await loadCcashBal(dispatch, ccash, account)
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}