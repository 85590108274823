import { combineReducers } from 'redux';

function wethContract(state = {}, action) {
  switch (action.type) {
    case 'WETH_CONTRACT_LOADED':
      return { ...state, loaded: true, weth: action.weth }
    case 'WETH_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, wethBal: action.wethBal }
      case 'WETH_POOL_DATA_LOADED':
        return { ...state, loaded: true, wethPoolData: action.wethPoolData }
    default:
      return state
  }
}

function wethUserinfo(state = {}, action) {
  switch (action.type) {
    case 'WETH_USER_INFO_LOADED':
      return { ...state, loaded: true, wethUserInfo: action.wethUserInfo }
    default:
      return state
  }
}

const wethReducer = combineReducers({
  wethContract,
  wethUserinfo,
})

export default wethReducer