import WBTC from '../../../backEnd/abis/ERC20/masterchef/BTC.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  wbtcContractLoaded,
  wbtcUserInfoLoaded,
  wbtcMasterChefBalanceLoaded,
  wbtcPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadWBTC = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const wbtc = new web3.eth.Contract(WBTC.abi, WBTC.networks[netId].address)
      dispatch(wbtcContractLoaded(wbtc))
      return wbtc
    } else {
      const wbtc = new web3.eth.Contract(WBTC.abi, WBTC.networks[netId].address)
      dispatch(wbtcContractLoaded(wbtc))
      return wbtc
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(wbtcContractLoaded(null))
    return null
  }
}



export const wbtcUpdate = async (dispatch) => {
  try{
    let account, web3, netId, wbtc, masterchef, masterChefAddy
    let poolID = 4
    
    await update(dispatch)


    web3 = await loadWeb3Bank(dispatch)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()

    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      wbtc = new web3.eth.Contract(WBTC.abi, WBTC.networks[netId].address)
      masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)

      await getWBTCBalance(dispatch, wbtc, masterChefAddy, account)
      await fetchWBTCPoolData(dispatch, poolID, masterchef)
      await WBTCUserInfo(dispatch, account, poolID, masterchef)

    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      wbtc = await loadWBTC(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
      await getWBTCBalance(dispatch, wbtc, masterChefAddy, account)
      await fetchWBTCPoolData(dispatch, poolID, masterchef)
      await WBTCUserInfo(dispatch, account, poolID, masterchef)
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const getWBTCBalance = async (dispatch, wbtc, masterChefAddy, account) => {
  try{
    let mcWBTCBal, userWBTCBal, allowance
    let wbtcBalance = []
    let wbtcApproveBool = false
    mcWBTCBal = await wbtc.methods.balanceOf(masterChefAddy).call() / 10**8
    console.log(account)
    if(account !== null) {
      userWBTCBal = await wbtc.methods.balanceOf(account).call() / 10**8
      allowance = await wbtc.methods.allowance(account, masterChefAddy).call()  / 10**8

      console.log(allowance)
      if(allowance >= userWBTCBal) {
        wbtcApproveBool = true
      }
    } else {
      userWBTCBal = 0
    }

    wbtcBalance[0] = {
      mcWBTCBal,
      userWBTCBal,
      wbtcApproveBool
    }

    dispatch(wbtcMasterChefBalanceLoaded(wbtcBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}


export const fetchWBTCPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let wbtcPoolInfo
    // WBTC poolID = 4
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    wbtcPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }

    dispatch(wbtcPoolDataLoaded(wbtcPoolInfo))
    console.log(wbtcPoolInfo)
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const WBTCUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let wbtcUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _ccashPendingCCASH = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let wbtcPendingCCASH = _ccashPendingCCASH / 10**18
      wbtcUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**8,
        wbtcPendingCCASH
      }
      dispatch(wbtcUserInfoLoaded(wbtcUserInfo))
    } else {
      wbtcUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        wbtcPendingCCASH: 0
      }
      dispatch(wbtcUserInfoLoaded(wbtcUserInfo))
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
  console.log('wbtcUserInfo')
  console.log(wbtcUserInfo)

}

export const approveWBTC = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let wbtc = await loadWBTC(dispatch, web3, netId)
  try {
    let amount = 1000000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await wbtc.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        wbtcUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositWBTC = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 4
  let newAmount = amount * 10**8
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        wbtcUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawWBTC = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 4
  let newAmount = amount * 10**8
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        wbtcUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

