import { combineReducers } from 'redux';

function quickContract(state = {}, action) {
  switch (action.type) {
    case 'QUICK_CONTRACT_LOADED':
      return { ...state, loaded: true, quick: action.quick }
    case 'QUICK_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, quickBal: action.quickBal }
      case 'QUICK_POOL_DATA_LOADED':
        return { ...state, loaded: true, quickPoolData: action.quickPoolData }
    default:
      return state
  }
}

function quickUserinfo(state = {}, action) {
  switch (action.type) {
    case 'QUICK_USER_INFO_LOADED':
      return { ...state, loaded: true, quickUserInfo: action.quickUserInfo }
    default:
      return state
  }
}

const quickReducer = combineReducers({
  quickContract,
  quickUserinfo,
})

export default quickReducer