import { createSelector } from 'reselect'
import { get } from 'lodash'


const wethContract = state => get(state, 'DeFiBankReducer.wethReducer.wethContract.weth')
export const wethContractSelector = createSelector(wethContract, c => c)

const wethMasterChefBal = state => get(state, 'DeFiBankReducer.wethReducer.wethContract.wethBal')
export const wethMasterChefBalSelector = createSelector(wethMasterChefBal, b => b)

const wethPoolData = state => get(state, 'DeFiBankReducer.wethReducer.wethContract.wethPoolData')
export const wethPoolDataSelector = createSelector(wethPoolData, p => p)

const wethUserinfo = state => get(state, 'DeFiBankReducer.wethReducer.wethUserinfo.wethUserInfo')
export const wethUserInfoSelector = createSelector(wethUserinfo, u => u)