import blogimg from "../../images/coinopolis-nft.jpg";
import blogimg2 from "../../images/blog/boostNFTs.png";
// import blogimg3 from "../../images/blog_3.jpg";


const Blogdata = [
    { imgsrc: blogimg, title: "How to buy and Sell NFTs", article: "let me introduce you to Coinopolis, the place for NFTs and the metaverse! NFTs can bought and sold all in our...", author: "Group Effort", date: "Dec 14, 2021", link: "/how-to-make-an-nft-then-sell-it-online" },

    { imgsrc: blogimg2, title: "Crypto Blockchain NFT Metaverse Keyterms", article: "metaverse? NFTs? Blockchain? There are many words floating around crypto.", author: "Matt Cannon", date: "Dec 20, 2021", link: "/crypto-blockchain-nft-metaverse-keyterms" },

    // { imgsrc: blogimg2, title: "Decentralizing NFT metadata on Coinopolis", article: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam tortor, ultrices accumsan mauris eget, pulvinar tincidunt erat. Sed nisi nisi, rutrum sit amet elit.", author: "Matt Cannon", date: "Dec 12, 2021" },

    // { imgsrc: blogimg3, title: " How to Make an NFT, Then Sell It Online", article: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam tortor, ultrices accumsan mauris eget, pulvinar tincidunt erat. Sed nisi nisi, rutrum sit amet elit.", author: "Matt Cannon", date: "Dec 21, 2021" },

];
export default Blogdata;
