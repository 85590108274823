export const navItems = [
    {
      id: 1,
      title: "Home",
      path: "./",
      cName: "nav-item",
    },
    {
      id: 2,
      title: "Markets",
      path: "./CryptoCubbies",
      cName: "nav-item",
    },
    {
      id: 3,
      title: "SpaceOdyssey",
      path: "./SOMarket",
      cName: "nav-item",
    },
    // {
    //   id: 4,
    //   title: "Contact Us",
    //   path: "./contactus",
    //   cName: "nav-item",
    // },
  ];
  
  export const marketsDropdownList = [
    {
      id: 1,
      title: "CryptoCubbies",
      path: "./CryptoCubbies",
      cName: "submenu-item",
    },
    {
      id: 2,
      title: "SpaceOdyssey",
      path: "./SOMarket",
      cName: "submenu-item",
    },
    // {
    //   id: 3,
    //   title: "Design",
    //   path: "./design",
    //   cName: "submenu-item",
    // },
    // {
    //   id: 4,
    //   title: "Development",
    //   path: "./development",
    //   cName: "submenu-item",
    // },
  ];
  

  export const soDropdownList = [
    {
      id: 1,
      title: "Play S.O.",
      path: "./PlaySO",
      cName: "submenu-item",
    },
    {
      id: 2,
      title: "NFT Market",
      path: "./SOMarket",
      cName: "submenu-item",
    },
  ];