import { listNFT, safeTransferFrom, setApprovalForAll } from '../../store/cubby/interactions'
import React, { Component } from 'react';
import { connect } from 'react-redux'

import {
  metadataSelector,
} from '../../store/cubby/selectors'


class CubbySendForm extends Component {

  render(props) {
    return (
      <div>

        {!this.props.metadata.isApprovedForAll ?
          <button
            type="Success"
            className='btn btn-primary btn-bordered-white mb-2'
            onClick={(e) => setApprovalForAll(this.props.dispatch)}
          >
            <b>Approve</b>
          </button>
          :
          <form
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '5px',
            }}
            onSubmit={(event) => {
              event.preventDefault();
              listNFT(this.props.dispatch, this.props.tokenID, this.listValue.value);
            }}
          >
            <input
              id='listValue'
              type='text'
              ref={(input) => (this.listValue = input)}
              className='form-control  mr-2'
              placeholder='List Price'
              required
            />
            <button type='submit' className='btn btn-primary' style={{ padding: "12px 20px" }}>
              &nbsp;&nbsp;List&nbsp;
            </button>
          </form>

        }

        <form
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0px',
          }}
          onSubmit={(event) => {
            event.preventDefault();
            safeTransferFrom(this.props.dispatch, this.sendAddress.value, this.props.tokenID);
          }}
        >
          <input
            id='sendAddress'
            type='text'
            ref={(input) => (this.sendAddress = input)}
            className='form-control  mr-2'
            placeholder="Address"
            required
          />
          <button type='submit' className='btn btn-primary' style={{ padding: "12px 20px" }}>
            Send
          </button>
        </form>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metadata: metadataSelector(state),
  }
}

export default connect(mapStateToProps)(CubbySendForm)