import React, { useState } from 'react'

const CubbyHelp = () => {
    const [showHelp, setShowHelp] = useState(false)
    const handleClick = () => setShowHelp(!showHelp);


    return (
        <div onClick={handleClick} className="pt-3 text-center">
            {!showHelp ? <p>need help? <span className="link3"><a>Click Here!</a></span></p> :
                <div>
                    <p>
                        CryptoCubbies are fun little pfps (social media profile picutures) back by blockchain. Each one of the original 12 have been hand crafted.
                    </p>
                    <p>
                        We do have some plans that we would like to incorporate the cubbies into GameFi, such as the ability to use the Cubby as the Hero in game,
                        or the ability to lend out your CryptoCubby. Lots of ideas around what we can do, but until then,
                        we hope you enjoy them!
                    </p>
                    <p>
                        MARKET HELP: You will need to approve/unlock the Market contract to transfer ownership of the crypto cubby, as well as spend your Coinopolis Cash.
                        If you experience an issue, please ensure that you do not see any button that says approve or unlock.
                    </p>
                </div>
            }
        </div >
    )
}

export default CubbyHelp;