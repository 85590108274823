export function cstContractLoaded(cst) {
  return {
    type: 'CST_CONTRACT_LOADED',
    cst
  }
}

export function cstMasterChefBalanceLoaded(cstBal) {
  return {
    type: 'CST_MASTERCHEFBAL_LOADED',
    cstBal
  }
}

export function cstPoolDataLoaded(cstPoolData) {
  return {
    type: 'CST_POOL_DATA_LOADED',
    cstPoolData
  }
}

export function cstUserInfoLoaded(cstUserInfo) {
  return {
    type: 'CST_USER_INFO_LOADED',
    cstUserInfo
  }
}