export function usdtContractLoaded(usdt) {
  return {
    type: 'USDT_CONTRACT_LOADED',
    usdt
  }
}

export function usdtMasterChefBalanceLoaded(usdtBal) {
  return {
    type: 'USDT_MASTERCHEFBAL_LOADED',
    usdtBal
  }
}

export function usdtPoolDataLoaded(usdtPoolData) {
  return {
    type: 'USDT_POOL_DATA_LOADED',
    usdtPoolData
  }
}

export function usdtUserInfoLoaded(usdtUserInfo) {
  return {
    type: 'USDT_USER_INFO_LOADED',
    usdtUserInfo
  }
}