import React from 'react'
import Breadcrumb from '../spaceodyssey/BreadcrumbSO'
import Navbar3 from '../Navbar/Navbar3'
import FooterNew from '../FooterNew'
import Blogdata from './BlogData'
import BlogCards from './BlogCards'

const Blog = () => {



    return (
        <>
            <Navbar3 />
            <Breadcrumb name="Blog" />
            <section className="blog-area load-more">
                <div className="container">
                    <div className="row items">
                        {Blogdata.map((val, ind) => {
                            return (
                                <BlogCards
                                    key={ind}
                                    title={val.title}
                                    article={val.article}
                                    author={val.author}
                                    date={val.date}
                                    imgsrc={val.imgsrc}
                                    link={val.link}
                                />
                            );
                        })}
                    </div>
                </div>
            </section>

            <FooterNew />

        </>
    )
}

export default Blog;