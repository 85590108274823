import React, { useState } from 'react'

const SOHelp = () => {
    const [showHelp, setShowHelp] = useState(false)
    const handleClick = () => setShowHelp(!showHelp);


    return (
        <div onClick={handleClick} className="pt-3">
            {!showHelp ? <p>Need help? <span className="link3"><a>Click Here!</a></span></p> :
                <div>
                    <p>
                        The Space Odyssey Market is a place where gamers can buy game item NFTs for use in our Space Odyssey Survival game.
                    </p>
                    <p>
                        Each item has a set of unique attributes that will be given to your hero once you have equipped the item in the Hero's inventory.
                    </p>
                    <p>
                        MARKET HELP: You will need to approve/unlock the Market contract to spend your Coinopolis Cash prior to being able to purchase a game item NFT.
                    </p>
                </div>
            }
        </div>
    )
}

export default SOHelp;