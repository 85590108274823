import QUICK from '../../../backEnd/abis/ERC20/masterchef/Quick.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  quickContractLoaded,
  quickUserInfoLoaded,
  quickMasterChefBalanceLoaded,
  quickPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadQUICK = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const quick = new web3.eth.Contract(QUICK.abi, QUICK.networks[netId].address)
      dispatch(quickContractLoaded(quick))
      return quick
    } else {
      const quick = new web3.eth.Contract(QUICK.abi, QUICK.networks[netId].address)
      dispatch(quickContractLoaded(quick))
      return quick
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(quickContractLoaded(null))
    return null
  }
}



export const quickUpdate = async (dispatch) => {
  try{
    let account, web3, netId, quick, masterchef, masterChefAddy
    let poolID = 5


    await update(dispatch)

    
    web3 = await loadWeb3Bank(dispatch)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()



    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      quick = new web3.eth.Contract(QUICK.abi, QUICK.networks[netId].address)
      masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)

      await getQUICKBalance(dispatch, quick, masterChefAddy, account)
      await fetchQUICKPoolData(dispatch, poolID, masterchef)
      await QUICKUserInfo(dispatch, account, poolID, masterchef)
      
    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      quick = await loadQUICK(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
  
      await getQUICKBalance(dispatch, quick, masterChefAddy, account)
      await fetchQUICKPoolData(dispatch, poolID, masterchef)
      await QUICKUserInfo(dispatch, account, poolID, masterchef)
    }


  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const getQUICKBalance = async (dispatch, quick, masterChefAddy, account) => {
  try{
    let mcQUICKBal, userQUICKBal, allowance
    let quickBalance = []
    let quickApproveBool = false
    mcQUICKBal = await quick.methods.balanceOf(masterChefAddy).call() / 10**18
    console.log(account)
    if(account !== null) {
      userQUICKBal = await quick.methods.balanceOf(account).call() / 10**18
      allowance = await quick.methods.allowance(account, masterChefAddy).call()  / 10**18

      console.log(allowance)
      if(allowance >= userQUICKBal) {
        quickApproveBool = true
      }
    } else {
      userQUICKBal = 0
    }

    quickBalance[0] = {
      mcQUICKBal,
      userQUICKBal,
      quickApproveBool
    }

    dispatch(quickMasterChefBalanceLoaded(quickBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}


export const fetchQUICKPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let quickPoolInfo
    // QUICK poolID = 5
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    quickPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }

    dispatch(quickPoolDataLoaded(quickPoolInfo))
    console.log(quickPoolInfo)
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const QUICKUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let quickUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _ccashPendingCCASH = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let quickPendingCCASH = _ccashPendingCCASH / 10**18
      quickUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**18,
        quickPendingCCASH
      }
      dispatch(quickUserInfoLoaded(quickUserInfo))
    } else {
      quickUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        quickPendingCCASH: 0
      }
      dispatch(quickUserInfoLoaded(quickUserInfo))
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
  console.log('quickUserInfo')
  console.log(quickUserInfo)

}

export const approveQUICK = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let quick = await loadQUICK(dispatch, web3, netId)
  try {
    let amount = 1000000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await quick.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        quickUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositQUICK = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 5
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        quickUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawQUICK = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 5
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        quickUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

