import { createSelector } from 'reselect'
import { get } from 'lodash'


const wmaticContract = state => get(state, 'DeFiBankReducer.wmaticReducer.wmaticContract.wmatic')
export const wmaticContractSelector = createSelector(wmaticContract, c => c)

const wmaticMasterChefBal = state => get(state, 'DeFiBankReducer.wmaticReducer.wmaticContract.wmaticBal')
export const wmaticMasterChefBalSelector = createSelector(wmaticMasterChefBal, b => b)

const wmaticPoolData = state => get(state, 'DeFiBankReducer.wmaticReducer.wmaticContract.wmaticPoolData')
export const wmaticPoolDataSelector = createSelector(wmaticPoolData, p => p)

const wmaticUserinfo = state => get(state, 'DeFiBankReducer.wmaticReducer.wmaticUserinfo.wmaticUserInfo')
export const wmaticUserInfoSelector = createSelector(wmaticUserinfo, u => u)