import { combineReducers } from 'redux';

function cstContract(state = {}, action) {
  switch (action.type) {
    case 'CST_CONTRACT_LOADED':
      return { ...state, loaded: true, cst: action.cst }
    case 'CST_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, cstBal: action.cstBal }
      case 'CST_POOL_DATA_LOADED':
        return { ...state, loaded: true, cstPoolData: action.cstPoolData }
    default:
      return state
  }
}

function cstUserinfo(state = {}, action) {
  switch (action.type) {
    case 'CST_USER_INFO_LOADED':
      return { ...state, loaded: true, cstUserInfo: action.cstUserInfo }
    default:
      return state
  }
}

const cstReducer = combineReducers({
  cstContract,
  cstUserinfo,
})

export default cstReducer