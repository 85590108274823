import React from 'react'
// import { update } from '../../store/interactions'
import Navbar3 from '../Navbar/Navbar3'
import HomeHeader from './Homeheader'
import NewFooter from '../FooterNew'
import HomeNftCards from '../cubbymarket/HomeNftCards'
import about from '../../images/about.png'
import howtowork from '../../images/howtowork.png'
import HowToWork from './HowToWork'

const Home = (props) => {
    // useEffect(() => {
    //     update(props.dispatch);
    //   }, []);

    return (
        <div>
            <Navbar3 />
            <HomeHeader />
            <section className="main-text">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro m-0">
                                <div className="intro-content">
                                    <span>Greetings</span>
                                    <h3 className="mt-3 mb-0">Future Citizens of Coinopolis.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="home-content">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-6 pl-2">
                                    <div className="ml-4">
                                        <p className="pt-2">
                                            As we move towards a more meta future, we wanted to explain some things about Coinopolis and how we hope to fit in.
                                            Just to let you know, we have no clue where we will end up, but we are set on always pressing forward.

                                            Coinopolis is a place in the metaverse, as well as an NFT marketplace built to give artists and game creators a place to create Initial NFT Offerings. If you would like to launch your NFT with us,
                                            please email info@coinopolis.io, we would love to help out!
                                            <br /><br />
                                            Our focus is on the future of the human race, and how the meta world will play a role in our daily interactions.
                                            From digital goods to digital worlds, we hope to be a platform for innovation and growth.
                                            <br /><br />
                                            Our smart contracts have been heavily audited and analyzed and we hope they are from bugs. Please understand the potential risks associated with smart contracts and use at your own risk.<br /><br />

                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-6">
                                    <img src={about} className="img-fluid vert-move" alt="Buy NFT Crypto" />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-6">
                                    <img src={howtowork} className="img-fluid vert-move" alt="Buy NFT Crypto" />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-6 pl-2">
                                    <h3 className="mt-3 mb-0">About NFT</h3>
                                    <div className="ml-3">
                                        <p className="pt-2">
                                            NFTs are a really simple concept, so don't get confused by people trying to make themselves sound overly smart.
                                            An NFT is a digital asset that is backed by a blockchain.
                                            <br /><br />
                                            NFTs can represent number of things from game items, digital trading cards, art, or... well, basically anything that you would want to be able to digitally prove ownership over.
                                            <br /><br />
                                            Of course, yes, you can just right click and save a jpeg, just like you can go out a buy a knock off designer bag, or get a copy of a painting.
                                            The beauty of the NFT is that it gives the owner proof of ownership, and the ability to verify if a good is a knock off or the real deal.
                                            Gamers will be able to use Coinopolis to buy and sell game items; Boosting their game performance through better items, or selling their items for profit.
                                            A place for artists to get support from communities and display their collections to the meta/physical-verse.<br /><br />
                                            There is still lots to discover here, but we appreciate your assitance in this task.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-12 pl-2">
                            <h3 className="mt-3 mb-0">How To</h3>
                            <div className="ml-3">
                                <p className="pt-2">
                                    It can be intimidating to think about moving funds from Ethereum to Polygon(MATIC), but you can do it. Learn how to configure your MetaMask wallet to the Polygon(Matic) Network and how to move funds from Ethereum to Polygon/MATIC by clicking the corrosponding link below:
                                    <br /><br />
                                    <p className="link3">
                                        <a
                                            href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="link3"

                                        >
                                            MetaMask Configuration
                                        </a>
                                    </p>
                                    <p className="link3"><a
                                        href="https://blog.matic.network/deposits-and-withdrawals-on-matic-network-walkthrough-guide/"
                                        target="_blank"
                                        rel="noopener noreferrer"


                                    >
                                        Move Funds to Polygon/MATIC
                                    </a></p>
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* <CubbyMarket /> */}
            <HomeNftCards />
            <HowToWork />
            <NewFooter />
        </div>
    )
}


export default Home