export function wethContractLoaded(weth) {
  return {
    type: 'WETH_CONTRACT_LOADED',
    weth
  }
}

export function wethMasterChefBalanceLoaded(wethBal) {
  return {
    type: 'WETH_MASTERCHEFBAL_LOADED',
    wethBal
  }
}

export function wethPoolDataLoaded(wethPoolData) {
  return {
    type: 'WETH_POOL_DATA_LOADED',
    wethPoolData
  }
}

export function wethUserInfoLoaded(wethUserInfo) {
  return {
    type: 'WETH_USER_INFO_LOADED',
    wethUserInfo
  }
}