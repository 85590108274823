import { createSelector } from 'reselect'
import { get } from 'lodash'


const wbtcContract = state => get(state, 'DeFiBankReducer.wbtcReducer.wbtcContract.wbtc')
export const wbtcContractSelector = createSelector(wbtcContract, c => c)

const wbtcMasterChefBal = state => get(state, 'DeFiBankReducer.wbtcReducer.wbtcContract.wbtcBal')
export const wbtcMasterChefBalSelector = createSelector(wbtcMasterChefBal, b => b)

const wbtcPoolData = state => get(state, 'DeFiBankReducer.wbtcReducer.wbtcContract.wbtcPoolData')
export const wbtcPoolDataSelector = createSelector(wbtcPoolData, p => p)

const wbtcUserinfo = state => get(state, 'DeFiBankReducer.wbtcReducer.wbtcUserinfo.wbtcUserInfo')
export const wbtcUserInfoSelector = createSelector(wbtcUserinfo, u => u)