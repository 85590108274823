import { createSelector } from 'reselect'
import { get } from 'lodash'


const ccashContract = state => get(state, 'DeFiBankReducer.ccashReducer.ccashContract.ccash')
export const ccashContractSelector = createSelector(ccashContract, c => c)

const ccashMasterChefBal = state => get(state, 'DeFiBankReducer.ccashReducer.ccashContract.ccashBal')
export const ccashMasterChefBalSelector = createSelector(ccashMasterChefBal, b => b)

const ccashPoolData = state => get(state, 'DeFiBankReducer.ccashReducer.ccashContract.ccashPoolData')
export const ccashPoolDataSelector = createSelector(ccashPoolData, p => p)

const ccashUserinfo = state => get(state, 'DeFiBankReducer.ccashReducer.ccashUserinfo.ccashUserInfo')
export const ccashUserInfoSelector = createSelector(ccashUserinfo, u => u)