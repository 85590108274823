import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { purchaseNFT, approveMarket, bulkPurchase } from '../../store/spaceodyssey/interactions'

import {
  accountSelector,
} from '../../store/selectors'

import {
  soNFTSelector,
  soMarketSelector,
  soMarketCCASHSelector,
  soCartSelector
} from '../../store/spaceodyssey/selectors'

// import SOUpdatePrice from './SOUpdatePrice'

import './cubby.css'
import backgroundImg from '../../images/SOSPoster.png'
import SOHelp from './SOHelp'
import BreadcrumbSO from './BreadcrumbSO';


const SOMarket = (props) => {

  return (
  <>
    <BreadcrumbSO name='SpaceOdyssey' />
    <div className="container text-center">

      <h3>SpaceOdyssey's Market</h3>
      <div>
        <a href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank' rel="noopener noreferrer">
          <button className='btn btn-primary btn-bordered-white'><i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>Buy $CCASH</button></a>
      </div>
      <div>
        <SOHelp />
      </div>
      <div>
        {props.cash === undefined ? <p>Loading...</p> :
        
          !props.cash.market_allowanceBool ?
            <div style={{ padding: '7px' }}>
              
              <p>Click unlock to gain the ability to purchase items.</p>
              <p><strong>In order to purchase items, you must approve the market contract to spend your CCASH by clicking unlock.</strong></p>
              <button
                type="Success"
                className='btn btn-primary btn-bordered-white'
                style={{ width: '50%' }}
                onClick={(e) => approveMarket(props.dispatch)}

              >
                <b>U N L O C K</b>
              </button>
            </div>

            :
            <div></div>
        }

      </div>



      <div className="row items">

        {props.listings === undefined || props.nfts === undefined ? <div>  loading </div> :
          <div className="cubbybox-container">{props.listings.map((nft, key) => {
            return (


              <div className="col-12 col-sm-6 col-lg-3 item mt-3" key={key}>
                <div className="card text-center">

                  <div className="image-over">
                    <a href={nft.image} target="_blank" rel="noopener noreferrer">
                      <img
                        className="card-img-top space-img"
                        src={nft.image}
                        alt='nft icon'

                      />
                    </a>
                  </div>
                  <div className="card-caption col-12 p-0">
                    <div className="card-body text-center">
                      <h5>MarketID {nft.id.toString()}</h5>
                      <div>
                        {nft.listed ?
                          <div>
                            <p>{Number(nft.price) / 10 ** 18} CCASH</p>
                          </div>
                          :
                          <div>
                            Price :
                            <p>{Number(nft.price) / 10 ** 18} CCASH</p>
                          </div>
                        }
                        {!nft.purchased ?
                        <div>
                          <button
                            type="Success"
                            className='btn btn-bordered-white btn-smaller'
                            onClick={(e) => purchaseNFT(props.dispatch, nft.id, nft.price) }
                          >
                            <i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>
                            <b>BUY</b>
                          </button>
                        </div>

                          :
                          <button
                            type=""
                            disabled
                            className='btn btn-bordered-white btn-smaller'
                          >
                            <i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>
                            <b>SOLDOUT</b>
                          </button>
                        }

                      </div>
                    </div>

                  </div>


                </div>
              </div>
            )
          })}</div>
        }
      </div>
      <div>
      <h3>YOUR CART</h3>
<div className="row items">
        {/* {loadCart()} */}
        <p>COMING SOON!</p>
</div>

      </div>
    </div>
  </>
  )
}

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    nfts: soNFTSelector(state),
    listings: soMarketSelector(state),
    // cart: soCartSelector(state),
    cash: soMarketCCASHSelector(state)
  }
}

export default connect(mapStateToProps)(SOMarket);