import { combineReducers } from 'redux';

function ccashContract(state = {}, action) {
  switch (action.type) {
    case 'CCASH_CONTRACT_LOADED':
      return { ...state, loaded: true, ccash: action.ccash }
    case 'CCASH_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, ccashBal: action.ccashBal }
      case 'CCASH_POOL_DATA_LOADED':
        return { ...state, loaded: true, ccashPoolData: action.ccashPoolData }
    default:
      return state
  }
}

function ccashUserinfo(state = {}, action) {
  switch (action.type) {
    case 'CCASH_USER_INFO_LOADED':
      return { ...state, loaded: true, ccashUserInfo: action.ccashUserInfo }
    default:
      return state
  }
}

const ccashReducer = combineReducers({
  ccashContract,
  ccashUserinfo,
})

export default ccashReducer