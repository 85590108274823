import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import './Style.css'

class FooterNew extends Component {
    render() {
        return (
            <footer className="footer-area">
                {/* Footer Top */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <Link className="navbar-logo navbar-logo2">
                                        {/* <Link to='/' className='navbar-logo'> */}
                                        Coinopolis
                                        {/* </Link> */}
                                    </Link>
                                    <p>
                                        A digital platform for the metaverse built on blockchain
                                    </p>
                                    {/* Social Icons */}
                                    <div className="new-social-icons d-flex">
                                        <a className="facebook" href="https://web.facebook.com/coinopolis.io/?_rdc=1&_rdr">
                                            <i className="fab fa-facebook-f" />
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a className="twitter" href="https://twitter.com/coinopolis_io">
                                            <i className="fab fa-twitter" />
                                            <i className="fab fa-twitter" />
                                        </a>
                                        <a className="instagram" href="https://www.instagram.com/coinopolis.io/">
                                            <i className="fab fa-instagram" />
                                            <i className="fab fa-instagram" />
                                        </a>
                                        <a className="envelope" href="mailto:info@coinopolis.io">
                                            <i className="fab fa fa-envelope" />
                                            <i className="fab fa fa-envelope" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Marketplace</h4>
                                    <ul>
                                        <li>
                                            <Link to='/'>Home</Link>
                                        </li>
                                        <li>
                                            <Link to='/CryptoCubbies'>Markets</Link>
                                        </li>
                                        <li>
                                            <Link to='/SOMarket'>SpaceOdyssey</Link>
                                        </li>
                                        <li>
                                            <Link to='/Farm'>Farm</Link>
                                        </li>
                                        <li>
                                            <Link to='/Blog'>Blog</Link>
                                        </li>
                                        <li className='nav-item'>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Useful Links</h4>
                                    <ul>
                                        <li>
                                            <Link to='/CryptoCubbies'> Crypto Cubbies</Link>
                                        </li>
                                        <li>
                                            <Link to='/SOMarket'>SpaceOdyssey</Link>
                                        </li>
                                        <li>
                                            <Link to='/PlaySO'>Play S.O</Link>
                                        </li>
                                        <li>
                                            <Link to='/SOMarket'>NFT Market</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Subscribe Us</h4>
                                    {/* Subscribe Form */}
                                    <div className="subscribe-form d-flex align-items-center">
                                        <input
                                            type="email"
                                            className="form-control"
                                            // "info@yourmail.com"
                                            placeholder="not available"
                                        />
                                        <button type="submit" className="btn">
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">

                                    <div className="copyright-left">
                                        ©2021 Coinopolis, All Rights Reserved.
                                    </div>

                                    <div className="copyright-right">
                                        Made with <i className="fas fa-heart" /> By
                                        <a href="https://github.com/mattarad"> coinopolis </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


export default FooterNew;