import React from 'react'
import { connect } from 'react-redux'
import { purchaseNFT, approveMarket, removeNFT } from '../../store/cubby/interactions'

import {
  accountSelector,
} from '../../store/selectors'

import {
  cubbyMarketNFTsSelector,
  ccashApproveSelector,
} from '../../store/cubby/selectors'

import CubbyUpdatePrice from './CubbyUpdatePrice'

import './cubby.css'
import CubbyHelp from './CubbyHelp'



const CubbyMarket = (props) => (


  <div className="container">

    <div className="row justify-content-center">
      <div className="col-12">
        {/* Intro */}
        <div className="intro text-center">
          <div className="intro-content">
            <h3 className="mt-3 mb-0">Coinopolis' Crypto Cubbies Market</h3>
            <p className="pt-3">There is a market fee of 500 $CCASH per Cubby, you will need to approve the Market to spend your $CCASH prior to purchase. </p>
          </div>
        </div>
      </div>
    </div>

    <div className="text-center">
      <a href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank' rel="noopener noreferrer">
        <button className='btn btn-primary'>Buy $CCASH</button></a>

    </div>
    <div>
      <CubbyHelp />
    </div>
    {!props.ccashApprove.market_allowanceBool ?
      <div className="text-center ">
        <p>Click unlock to gain the ability to purchase CryptoCubbies.</p>
        <button
          type="Success"
          className='btn btn-primary btn-bordered-white'
          style={{ width: '50%' }}
          onClick={(e) => approveMarket(props.dispatch)}

        >
          <b>U N L O C K</b>
        </button>
      </div>

      :
      <div></div>
    }


    <div className="row items">
      {props.listedCubbies === undefined ? <div> loading </div> :
        <div className="cubbybox-container">{props.listedCubbies.map((nft, key) => {
          return (

            <div className="col-12 col-sm-6 col-lg-3 item mt-3" key={key}>
              <div className="card text-center">

                <div className="image-over">
                  <a href={nft.tokenURIData.image} target="_blank" rel="noopener noreferrer">
                    <img
                      className="card-img-top img-fluid"
                      src={nft.tokenURIData.image}
                      alt='nft icon'

                    />
                  </a>
                </div>
                <div className="card-caption col-12 p-0">
                  <div className="card-body text-center">
                    <h5>MarketID {nft.nftMarketID}</h5>
                    <a href={nft.tokenURIData.image} target="_blank" rel="noopener noreferrer">
                    </a>
                    <div>
                      {nft.listed ?
                        <div>
                          <p>{nft.price / 10 ** 18} MATIC</p>
                          <p>+</p>
                          <p>{nft.ccashFee} CCASH</p>
                        </div>
                        :
                        <div>
                          last listed price :
                          <p>{nft.price / 10 ** 18} MATIC</p>
                          <p>+</p>
                          <p>{nft.ccashFee} CCASH</p>
                        </div>
                      }

                    </div>

                    {nft.owner === props.account ?
                      nft.listed ?
                        <div>
                          <button
                            type="Success"
                            className='btn btn-primary'
                            style={{ width: '100%' }}
                            onClick={(e) => removeNFT(props.dispatch, nft.nftMarketID)}
                          >
                            <b>remove</b>
                          </button>
                          <CubbyUpdatePrice nftMarketID={nft.nftMarketID} />
                        </div>
                        :
                        <p></p>
                      : <p></p>}
                    <div>
                      {nft.listed ?
                        nft.owner === props.account ?
                          <p></p>
                          :
                          <button
                            type="Success"
                            className='btn btn-primary btn-bordered-white'
                            style={{ width: '100%', margin: '5px' }}
                            onClick={(e) => purchaseNFT(props.dispatch, nft.nftMarketID, nft.price)}

                          >
                            <i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>

                            <b>B u y</b>
                          </button>
                        :
                        <button
                          type="Success"
                          disabled
                          className='btn btn-primary btn-bordered-white'

                        >
                          <i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>

                          <b>Sold</b>
                        </button>
                      }

                    </div>
                  </div>
                </div>

              </div>
            </div>

          )
        })}</div>
      }
    </div>
    <p style={{ padding: '5px' }} className="text-center mt-3">
      <a href='https://opensea.io/collection/cryptocubbies' target="_blank" rel="noopener noreferrer">
        <button className='btn btn-primary btn-bordered-white'><b>VIEW ON OPEN SEA</b></button>
      </a>
    </p>
  </div>
)

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    listedCubbies: cubbyMarketNFTsSelector(state),
    ccashApprove: ccashApproveSelector(state),
  }
}

export default connect(mapStateToProps)(CubbyMarket);