import React, { Component, Fragment } from "react";

import { connect } from 'react-redux'
import { soUpdate } from '../../store/spaceodyssey/interactions'
import { update } from '../../store/interactions'

import Unity, { UnityContext } from "react-unity-webgl";
import Navbar3 from '../Navbar/Navbar3'

import BreadcrumbSO from './BreadcrumbSO';

import './css/App.css'


import {
  soOwnedIPFSSelector,
} from '../../store/spaceodyssey/selectors'


import SOWallet from './SOWallet'
import FooterNew from "../FooterNew";


class PlaySO extends Component {
  async UNSAFE_componentWillMount() {
    await this.loadBlockchainData(this.props.dispatch)
  }

  async loadBlockchainData(dispatch) {
    /* Case 1, User connect for 1st time */
    if (typeof window.ethereum !== 'undefined') {
      await update(dispatch)
      await soUpdate(dispatch)
      /* Case 2 - User switch account */
      window.ethereum.on('accountsChanged', async () => {
        await update(dispatch)
        await soUpdate(dispatch)
      });
      /* Case 3 - User switch network */
      window.ethereum.on('chainChanged', async () => {
        await update(dispatch)
        await soUpdate(dispatch)
      });
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      degrees: 0,
      message: "-",
      showUnity: true,
    }

    this.unityContext = new UnityContext({
      loaderUrl: 'Build/SOv2.loader.js',
      dataUrl: 'Build/SOv2.data',
      frameworkUrl: 'Build/SOv2.framework.js',
      codeUrl: 'Build/SOv2.wasm'
    })
  }


  render() {
    return (
      <Fragment>
        <div className="main">
          <Navbar3 />
          <BreadcrumbSO name='Play S.O' />

          <div className="container mt-5">

            <div className="row justify-content-center">
              <div className="col-12">
                {/* Intro */}
                <div className="intro text-center">
                  <div className="intro-content">
                    <span>Play S.O</span>
                    <h3 className="mt-3 mb-0">Boost your game with NFTs from the Space Odyssey Market!</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3 mt-2 text-center">              {
              this.props.soIPFS === undefined ? <div> Loading... </div> :
                <button
                  className='btn btn-bordered-white'
                  type="Success"
                  children={"Load NFT Inventory"}
                  onClick={() => {
                    this.props.soIPFS.map(nft => {
                      console.log(nft)
                      this.unityContext.unityInstance.SendMessage(
                        "JavaScriptHook",
                        "GetItemData",
                        nft,
                      )
                    })
                  }}
                />
            }

              <button
                className="btn btn-bordered-white  mt-2"
                children={"(re)load"}
                onClick={() => this.setState({ showUnity: !this.state.showUnity })}
              />
            </div>
            <div>


              {this.state.showUnity === true ? (
                <Unity unityContext={this.unityContext}
                  style={{
                    // height: "100%",
                    width: '100%',
                    borderRadius: '15px',
                    border: "5px solid #a200ff",
                  }} />
              ) : (
                <div />
              )}
            </div>
            <p>
              <h4>Press <b>ESC</b> for in game instructions</h4>
              <ul>
                <li><b>Move: </b> press and hold Arrow Keys OR W-A-S-D keys | <b>Aim: </b> move mouse cursor | <b>Shoot: </b> left click</li>
                <li><b>Inventory: </b> I / i - for each NFTy you own, a Load NFTy button will appear</li>
                <li><b>whirlwind: </b> must have sword equipped and whirlwind level 1 or higher | Right click to use</li>
                <li><b>Dash: </b> level 1 or higher, press spacebar</li>
                <li><b>speed up: </b> increase movement speed</li>
                <li><b>health up: </b> increases health regeneration rate</li>
                <li><b>energy up: </b> increases energy regeneration rate</li>
              </ul>
            </p>
          </div>
          <div>
            <SOWallet /><br /><br />
            <FooterNew />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    // account: accountSelector(state),
    soIPFS: soOwnedIPFSSelector(state),
  }
}

export default connect(mapStateToProps)(PlaySO)