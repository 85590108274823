import DAI from '../../../backEnd/abis/ERC20/masterchef/DAI.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  daiContractLoaded,
  daiUserInfoLoaded,
  daiMasterChefBalanceLoaded,
  daiPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadDAI = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const dai = new web3.eth.Contract(DAI.abi, DAI.networks[netId].address)
      dispatch(daiContractLoaded(dai))
      return dai
    } else {
      const dai = new web3.eth.Contract(DAI.abi, DAI.networks[netId].address)
      dispatch(daiContractLoaded(dai))
      return dai
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(daiContractLoaded(null))
    return null
  }
}



export const daiUpdate = async (dispatch) => {
  try{
    let account, web3, netId, dai, masterchef, masterChefAddy
    let poolID = 8

    await update(dispatch)


    web3 = await loadWeb3Bank(dispatch)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()

    console.log(web3)

    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      dai = new web3.eth.Contract(DAI.abi, DAI.networks[netId].address)
      masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)

      await getDAIBalance(dispatch, dai, masterChefAddy, account)
      await fetchDAIPoolData(dispatch, poolID, masterchef)
      await DAIUserInfo(dispatch, account, poolID, masterchef)
    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      dai = await loadDAI(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
      await getDAIBalance(dispatch, dai, masterChefAddy, account)
      await fetchDAIPoolData(dispatch, poolID, masterchef)
      await DAIUserInfo(dispatch, account, poolID, masterchef)
    }

  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const getDAIBalance = async (dispatch, dai, masterChefAddy, account) => {
  try{
    let mcDAIBal, userDAIBal, allowance
    let daiBalance = []
    let daiApproveBool = false
    mcDAIBal = await dai.methods.balanceOf(masterChefAddy).call() / 10**18
    console.log(account)
    if(account !== null) {
      userDAIBal = await dai.methods.balanceOf(account).call() / 10**18
      allowance = await dai.methods.allowance(account, masterChefAddy).call()  / 10**18

      console.log(allowance)
      if(allowance >= userDAIBal) {
        daiApproveBool = true
      }
    } else {
      userDAIBal = 0
    }

    daiBalance[0] = {
      mcDAIBal,
      userDAIBal,
      daiApproveBool
    }

    dispatch(daiMasterChefBalanceLoaded(daiBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const fetchDAIPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let daiPoolInfo
    // DAI poolID = 8
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    daiPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }

    dispatch(daiPoolDataLoaded(daiPoolInfo))
    console.log(daiPoolInfo)
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const DAIUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let daiUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _ccashPendingCCASH = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let daiPendingCCASH = _ccashPendingCCASH / 10**18
      daiUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**18,
        daiPendingCCASH
      }
      dispatch(daiUserInfoLoaded(daiUserInfo))
    } else {
      daiUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        daiPendingCCASH: 0
      }
      dispatch(daiUserInfoLoaded(daiUserInfo))
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
  console.log('daiUserInfo')
  console.log(daiUserInfo)

}

export const approveDAI = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let dai = await loadDAI(dispatch, web3, netId)
  try {
    let amount = 1000000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await dai.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        daiUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositDAI = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 8
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        daiUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawDAI = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 8
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        daiUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

