import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from '../components/Home/Home'
import CubbyApp from '../components/cubbymarket/CubbyApp'
import SOApp from '../components/spaceodyssey/SOApp'
import PlaySO from '../components/spaceodyssey/PlaySO'
import dBankV2 from '../components/dBankV2/dBankAppV2'
import Blog from '../components/Blog/Blog'
import BlogSingle from '../components/Blog/BlogSngle'
import BlogDouble from '../components/Blog/BlogDouble'


const AppRouter = () => (
    <BrowserRouter>
        <div>
            <Switch>
                <Route path='/' component={Home} exact={true} />

                <Route path='/Blog' component={Blog} />
                <Route path='/how-to-make-an-nft-then-sell-it-online' component={BlogSingle} />
                <Route path='/crypto-blockchain-nft-metaverse-keyterms' component={BlogDouble} />
                <Route path='/CryptoCubbies' component={CubbyApp} />
                <Route path='/SOMarket' component={SOApp} />
                <Route path='/PlaySO' component={PlaySO} />
                <Route path='/Farm' component={dBankV2} />

            </Switch>
        </div>
    </BrowserRouter>
)

export default AppRouter