export function quickContractLoaded(quick) {
  return {
    type: 'QUICK_CONTRACT_LOADED',
    quick
  }
}

export function quickMasterChefBalanceLoaded(quickBal) {
  return {
    type: 'QUICK_MASTERCHEFBAL_LOADED',
    quickBal
  }
}

export function quickPoolDataLoaded(quickPoolData) {
  return {
    type: 'QUICK_POOL_DATA_LOADED',
    quickPoolData
  }
}

export function quickUserInfoLoaded(quickUserInfo) {
  return {
    type: 'QUICK_USER_INFO_LOADED',
    quickUserInfo
  }
}