export function masterchefContractLoaded(cst) {
  return {
    type: 'MC_CONTRACT_LOADED',
    cst
  }
}

export function masterchefBalanceLoaded(cstBal) {
  return {
    type: 'MC_MASTERCHEFBAL_LOADED',
    cstBal
  }
}

export function cstPoolDataLoaded(poolData) {
  return {
    type: 'MC_POOL_DATA_LOADED',
    poolData
  }
}

export function cstUserInfoLoaded(userInfo) {
  return {
    type: 'CST_USER_INFO_LOADED',
    userInfo
  }
}
