import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    networkSelector,
} from '../store/selectors'

import {
    cubbyNFTContractSelector,
} from '../store/cubby/selectors'


import './Style.css'



class Footer extends Component {
    render() {
        return (
            <div>

                <div className="container pt-5 pb-5">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-12">

                            {this.props.contract
                                ? <div>
                                    <p>for the nerds </p>
                                    cryptocubbies addy:&nbsp;
                                    <a
                                        href={`https://${this.props.network}.etherscan.io/address/` + this.props.contract._address}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#352095" }}
                                    >
                                        {this.props.contract._address}
                                    </a>
                                </div>
                                : <div> Wrong network </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        contract: cubbyNFTContractSelector(state),
        network: networkSelector(state),
    }
}

export default connect(mapStateToProps)(Footer);