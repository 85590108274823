import { safeTransferFrom } from '../../store/spaceodyssey/interactions'
import React, { Component } from 'react';
import { connect } from 'react-redux'

// import {
//     soMetaDataSelector
//   } from '../../store/spaceodyssey/selectors'


class SOSendForm extends Component {

  render(props) {
    return (
      <div>

        {/* { !this.props.metadata.isApprovedForAll ? 
                  <button
                      type="Success"
                      className='btn btn-primary'
                      style={{ width: '50%', marginBottom: '5px',}}
                      onClick={(e) => setApprovalForAll(this.props.dispatch)}
                    >
                  <b>approve</b>
                  </button>
              :
                  <form
                      style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '5px',
                      }}
                      onSubmit={(event) => {
                      event.preventDefault();
                      listNFT(this.props.dispatch, this.props.tokenID, this.listValue.value);
                      }}
                  >
                      <input
                      style={{ width: '60%' }}
                      id='listValue'
                      type='text'
                      ref={(input) => (this.listValue = input)}
                      className='form-control'
                      placeholder='list price'
                      required
                      />
                      <button type='submit' className='btn btn-primary' style={{ width: '30%' }}>
                      list
                      </button>
                  </form>
  
              } */}

        <form
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '0px',
          }}
          onSubmit={(event) => {
            event.preventDefault();
            safeTransferFrom(this.props.dispatch, this.sendAddress.value, this.props.tokenID);
          }}
        >
          <input
            id='sendAddress'
            type='text'
            ref={(input) => (this.sendAddress = input)}
            className='form-control  mr-2'
            placeholder='Address'
            required
          />
          <button type='submit' className='btn btn-primary' style={{ padding: "12px 20px" }}>
            Send
          </button>
        </form>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // metadata: soMetaDataSelector(state),
  }
}

export default connect(mapStateToProps)(SOSendForm)