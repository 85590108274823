import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../Style.css'
import './Navbar.css'
import MarketsDropdown from './MarketsDropdown';
import SODropdown from './SODropdown';
import metaMaskImg from '../../images/metamask.png';
// import Account from './Account'
import { connect } from 'react-redux'

import eth from '../../images/eth.png'
import matic from '../../images/matic-token.png'
import ccashRound from '../../images/ccash-round.png'

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'

import {
    accountSelector,
    balanceSelector,
    networkSelector,
    web3Selector,
    ccashBalSelector
} from '../../store/selectors'

// import {
//   ccashApproveSelector,
// } from '../../store/cubby/selectors'


function Navbar(props) {


    const [click, setClick] = useState(false);
    const [marketsdropdown, setMarketsdropdown] = useState(false);
    const [sodropdown, setSOdropdown] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <nav className='navbar d-block d-md-none d-lg-none d-sm-none'>
            <div className="container">
                <Link to='/' className='navbar-logo'>
                    Coinopolis
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' >
                            Home
                        </Link>
                    </li>

                    <Dropdown className='nav-item'>
                        <Dropdown.Toggle className='nav-links' style={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>

                            Markets

                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <MarketsDropdown />
                        </Dropdown.Menu>
                    </Dropdown>


                    <Dropdown className='nav-item'>
                        <Dropdown.Toggle className='nav-links' style={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>
                            SpaceOdyssey
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <SODropdown />
                        </Dropdown.Menu>


                    </Dropdown>
                    <li className='nav-item'>
                        <Link
                            to='/Farm'
                            className='nav-links'
                        >
                            Farm
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/Blog'
                            className='nav-links'
                        >
                            Blog
                        </Link>
                    </li>

                    <li className='nav-item'>
                        {props.account

                            ? <>


                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <span className="">
                                            <b>{props.balance} </b>
                                            {props.network !== 'Private' ?
                                                <img src={eth} width='18' height='18' alt="eth" /> :
                                                <img src={matic} width='18' height='18' alt="matic" />
                                            }
                                        </span>
                                        <span className="">
                                            <b>{Math.round(props.ccashBalance)} </b>
                                            <img src={ccashRound} width='18' height='18' alt="ccash" />
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="">{props.network}</Dropdown.Item>
                                        <Dropdown.Item href={props.account}>
                                            <div className="">
                                                <i className="fas fa-wallet"></i>
                                                {props.network === 'Main' || props.network === 'Private' || props.network === 'Wrong network'
                                                    ? <b><a
                                                        style={{ color: "#55FF55" }}
                                                        href={`https://etherscan.io/address/` + props.account}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >

                                                        {props.account.substring(32, 42) + '...'}
                                                        &nbsp;
                                                    </a></b>
                                                    : <b><a
                                                        style={{ color: "#55FF55" }}
                                                        href={`https://${props.network}.etherscan.io/address/` + props.account}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {props.account.substring(32, 42) + '...'}
                                                    </a></b>
                                                }
                                            </div></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            :
                            <ul className="navbar-nav">
                                {props.web3
                                    ? <button
                                        type="Success"
                                        // className="btn btn-outline btn-block "
                                        className="btn ml-lg-auto btn-bordered-white"
                                        // style={{ backgroundColor: "#55FF55", color: "#000000" }}
                                        onClick={async () => {
                                            try {
                                                await window.ethereum.enable()
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    >
                                        <img src={metaMaskImg} className="icon-wallet mr-md-2" height="20px" />

                                        L o g i n
                                    </button>
                                    : <button
                                        // className="btn btn-warning"
                                        className="btn ml-lg-auto btn-bordered-white"

                                        type="button"
                                        onClick={() => {
                                            try {
                                                window.open("https://metamask.io/")
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    >
                                        <img src={metaMaskImg} className="icon-wallet mr-md-2" height="20px" />

                                        Get MetaMask
                                    </button>
                                }
                            </ul>
                        }
                    </li>
                </ul>

            </div>
        </nav >
    );
}

// export default Navbar;

function mapStateToProps(state) {
    return {
        web3: web3Selector(state),
        account: accountSelector(state),
        network: networkSelector(state),
        balance: balanceSelector(state),
        ccashBalance: ccashBalSelector(state)
    }
}

export default connect(mapStateToProps)(Navbar)
