export function ccashContractLoaded(ccash) {
  return {
    type: 'CCASH_CONTRACT_LOADED',
    ccash
  }
}

export function ccashMasterChefBalanceLoaded(ccashBal) {
  return {
    type: 'CCASH_MASTERCHEFBAL_LOADED',
    ccashBal
  }
}

export function ccashPoolDataLoaded(ccashPoolData) {
  return {
    type: 'CCASH_POOL_DATA_LOADED',
    ccashPoolData
  }
}

export function ccashUserInfoLoaded(ccashUserInfo) {
  return {
    type: 'CCASH_USER_INFO_LOADED',
    ccashUserInfo
  }
}