import { cubbyUpdate } from '../../store/cubby/interactions'
import { update } from '../../store/interactions'
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Navbar3 from '../Navbar/Navbar3'
import CubbyMain from './CubbyMain'


class CubbyApp extends Component {
  async UNSAFE_componentWillMount() {
    await this.loadBlockchainData(this.props.dispatch)
  }

  async loadBlockchainData(dispatch) {
    /* Case 1, User connect for 1st time */
    if (typeof window.ethereum !== 'undefined') {
      await update(dispatch)
      await cubbyUpdate(dispatch)
      /* Case 2 - User switch account */
      window.ethereum.on('accountsChanged', async () => {
        await update(dispatch)
        await cubbyUpdate(dispatch)
      });
      /* Case 3 - User switch network */
      window.ethereum.on('chainChanged', async () => {
        await update(dispatch)
        await cubbyUpdate(dispatch)
      });
    }
  }

  render() {
    return (
      <div className="text-monospace  bgDark7" style={{ backgroundColor: '#111111' }}>

        <Navbar3 />
        <CubbyMain />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(CubbyApp)