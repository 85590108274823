import React, { Component } from 'react'
import Breadcrumb from './BreadcrumbCubby'
import { connect } from 'react-redux'

import background from '../../images/cubbies2.svg'

import {
  cubbyMarketNFTsSelector,
} from '../../store/cubby/selectors'

import FooterNew from '../FooterNew'

class CubbyLoading extends Component {
  render() {
    return (
      <>
        <Breadcrumb />
        <div className="container pt-5">
          <div className="loading " >

            <div className="intro text-center">
              <span>Make sure you connect your wallet to Polygon!</span>
              <h3 className="mt-3 mb-0">Coinopolis' Crypto Cubby Market</h3>
              {/* <div className="mt-4">
                <a href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank' rel="noopener noreferrer">
                  <button className='btn btn-primary'>Buy $CCASH</button></a>
              </div> */}
            </div>
            {/* <div className="main"
              style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'justify-flex',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}>
            </div> */}





            <div className="row items">
              {this.props.listedNFTs === undefined ?
                <div>
                  <p>Loading</p>
                  <p>If you haven't done so, please connect MetaMask</p>
                  <a
                    className="btn btn-bordered-white btn-smaller mt-3 btn-ccash"
                    href="href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank' "
                  >
                    <i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>
                    Buy $CCASH
                  </a>
                </div>
                :
                <div className="cubbybox-container">
                  {this.props.listedNFTs.map((nft, key) => {
                    return (
                      <div className="col-12 col-sm-6 col-lg-3 item mt-3" key={key}>
                        <div className="card">
                          <div className="image-over">
                            <a href={nft.tokenURIData.image} target="_blank" rel="noopener noreferrer">
                              <img
                                className="card-img-top"
                                src={nft.tokenURIData.image}
                                alt="Buy and Sell NFT"
                              />
                            </a>
                          </div>
                          {/* Card Caption */}
                          <div className="card-caption col-12 p-0">
                            {/* Card Body */}
                            <div className="card-body">
                              <a href="#">
                                <h5 className="mb-0">MarketID {nft.nftMarketID}</h5>
                              </a>
                              <div className="seller d-flex align-items-center my-3">
                                <span>info:</span>
                                <a href={`https://opensea.io/assets/matic/0xc828e44640ca9aa5385e22496bb844902478add0/${nft.nftMarketID}`} target="_blank">
                                  <h6 className="ml-1 mb-0"> &nbsp; view</h6>
                                </a>
                              </div>
                              <div className="card-bottom d-flex justify-content-between">
                                {nft.listed ?
                                  <span>{Number(nft.price) / 10 ** 18} MATIC + {Number(nft.ccashFee)} CCASH<br /><br /></span>
                                  :

                                  <span> {Number(nft.price) / 10 ** 18} MATIC + {Number(nft.ccashFee)} CCASH <br /><span className="listed-price">Last listed price</span></span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                    )
                  })}</div>
              }
            </div>

            <p className="text-center mt-5 mb-5">
              <a href='https://opensea.io/collection/cryptocubbies' target="_blank" rel="noopener noreferrer">
                <button className='btn btn-primary'><b>VIEW ON OPEN SEA</b></button>
              </a>
            </p>
          </div>
        </div>
        <FooterNew />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    listedNFTs: cubbyMarketNFTsSelector(state),
  }
}

export default connect(mapStateToProps)(CubbyLoading)