export function soNFTContractLoaded(contract) {
  return {
    type: 'SO_CONTRACT_LOADED',
    contract
  }
}

export function soMetaDataLoaded(metadata) {
  return {
    type: 'SO_METADATA_LOADED',
    metadata
  }
}

export function soNFTsLoaded(nfts) {
  return {
    type: 'SO_NFTS_LOADED',
    nfts
  }
}

export function soOwnedNFTsLoaded(owned) {
  return {
    type: 'SO_OWNEDNFTS_LOADED',
    owned
  }
}

export function soOwnedIPFSLoaded(ipfs) {
  return {
    type: 'SO_IPFS_LOADED',
    ipfs
  }
}

export function soMarketLoaded(contract) {
  return {
    type: 'SOMARKET_CONTRACT_LOADED',
    contract
  }
}

export function soCartLoaded(listings) {
  return {
    type: 'SOMARKET_CART_LOADED',
    listings
  }
}

export function soMarketNFTsLoaded(listings) {
  return {
    type: 'SOMARKET_NFTS_LOADED',
    listings
  }
}

export function soCCASHApproveLoaded(ccash) {
  return {
    type: 'SOMARKET_CCASH_LOADED',
    ccash
  }
}