import { createSelector } from 'reselect'
import { get } from 'lodash'


const usdcContract = state => get(state, 'DeFiBankReducer.usdcReducer.usdcContract.usdc')
export const usdcContractSelector = createSelector(usdcContract, c => c)

const usdcMasterChefBal = state => get(state, 'DeFiBankReducer.usdcReducer.usdcContract.usdcBal')
export const usdcMasterChefBalSelector = createSelector(usdcMasterChefBal, b => b)

const usdcPoolData = state => get(state, 'DeFiBankReducer.usdcReducer.usdcContract.usdcPoolData')
export const usdcPoolDataSelector = createSelector(usdcPoolData, p => p)

const usdcUserinfo = state => get(state, 'DeFiBankReducer.usdcReducer.usdcUserinfo.usdcUserInfo')
export const usdcUserInfoSelector = createSelector(usdcUserinfo, u => u)