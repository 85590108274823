import WMATIC from '../../../backEnd/abis/ERC20/masterchef/WMATIC.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  wmaticContractLoaded,
  wmaticUserInfoLoaded,
  wmaticMasterChefBalanceLoaded,
  wmaticPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadWMATIC = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const wmatic = new web3.eth.Contract(WMATIC.abi, WMATIC.networks[netId].address)
      dispatch(wmaticContractLoaded(wmatic))
      return wmatic
    } else {
      const wmatic = new web3.eth.Contract(WMATIC.abi, WMATIC.networks[netId].address)
      dispatch(wmaticContractLoaded(wmatic))
      return wmatic
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(wmaticContractLoaded(null))
    return null
  }
}



export const wmaticUpdate = async (dispatch) => {
  try{
    let account, web3, netId, wmatic, masterchef, masterChefAddy
    let poolID = 2
    
    await update(dispatch)


    web3 = await loadWeb3Bank(dispatch)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()


    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      wmatic = new web3.eth.Contract(WMATIC.abi, WMATIC.networks[netId].address)
      masterchef = new web3.eth.Contract(MasterChef.abi, MasterChef.networks[netId].address)
      
      await getWMATICBalance(dispatch, wmatic, masterChefAddy, account)
      await fetchWMATICPoolData(dispatch, poolID, masterchef)
      await WMATICUserInfo(dispatch, account, poolID, masterchef)

    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      wmatic = await loadWMATIC(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
      await getWMATICBalance(dispatch, wmatic, masterChefAddy, account)
      await fetchWMATICPoolData(dispatch, poolID, masterchef)
      await WMATICUserInfo(dispatch, account, poolID, masterchef)
  
    }

  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const getWMATICBalance = async (dispatch, wmatic, masterChefAddy, account) => {
  try{
    let mcWMATICBal, userWMATICBal, allowance
    let wmaticBalance = []
    let wmaticApproveBool = false
    mcWMATICBal = await wmatic.methods.balanceOf(masterChefAddy).call() / 10**18
    console.log(account)
    if(account !== null) {
      userWMATICBal = await wmatic.methods.balanceOf(account).call() / 10**18
      allowance = await wmatic.methods.allowance(account, masterChefAddy).call()  / 10**18

      console.log(allowance)
      if(allowance >= userWMATICBal) {
        wmaticApproveBool = true
      }
    } else {
      userWMATICBal = 0
    }

    wmaticBalance[0] = {
      mcWMATICBal,
      userWMATICBal,
      wmaticApproveBool
    }

    dispatch(wmaticMasterChefBalanceLoaded(wmaticBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}


export const fetchWMATICPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let wmaticPoolInfo
    // WMATIC poolID = 2
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    wmaticPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }

    dispatch(wmaticPoolDataLoaded(wmaticPoolInfo))
    console.log(wmaticPoolInfo)
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const WMATICUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let wmaticUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _ccashPendingCCASH = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let wmaticPendingCCASH = _ccashPendingCCASH / 10**18
      wmaticUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**18,
        wmaticPendingCCASH
      }
      dispatch(wmaticUserInfoLoaded(wmaticUserInfo))
    } else {
      wmaticUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        wmaticPendingCCASH: 0
      }
      dispatch(wmaticUserInfoLoaded(wmaticUserInfo))
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
  console.log('wmaticUserInfo')
  console.log(wmaticUserInfo)

}

export const approveWMATIC = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let wmatic = await loadWMATIC(dispatch, web3, netId)
  try {
    let amount = 1000000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await wmatic.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        wmaticUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositWMATIC = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 2
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        wmaticUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawWMATIC = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 2
  let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        wmaticUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

