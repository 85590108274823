import { createSelector } from 'reselect'
import { get } from 'lodash'


const cubbyNFTContract = state => get(state, 'cubbyNFTContract.contract')
export const cubbyNFTContractSelector = createSelector(cubbyNFTContract, c => c)

const metadata = state => get(state, 'cubbyNFTContract.metadata')
export const metadataSelector = createSelector(metadata, m => m)

const cubbyContractNFTs = state => get(state, 'cubbyNFTContract.nfts')
export const cubbyContractNFTsSelector = createSelector(cubbyContractNFTs, n => n)

const ccashApprove = state => get(state, 'ccash.approve', 0)
export const ccashApproveSelector = createSelector(ccashApprove, l => l)

const cubbyMarketNFTs = state => get(state, 'cubbymarket.listings')
export const cubbyMarketNFTsSelector = createSelector(cubbyMarketNFTs, p => p)

const cubbyNFTs = state => get(state, 'cubbymarket.market')
export const cubbyNFTsSelector = createSelector(cubbyNFTs, p => p)