import { createSelector } from 'reselect'
import { get } from 'lodash'


const cstContract = state => get(state, 'DeFiBankReducer.cstReducer.cstContract.cst')
export const cstContractSelector = createSelector(cstContract, c => c)

const cstMasterChefBal = state => get(state, 'DeFiBankReducer.cstReducer.cstContract.cstBal')
export const cstMasterChefBalSelector = createSelector(cstMasterChefBal, b => b)

const cstPoolData = state => get(state, 'DeFiBankReducer.cstReducer.cstContract.cstPoolData')
export const cstPoolDataSelector = createSelector(cstPoolData, p => p)

const cstUserinfo = state => get(state, 'DeFiBankReducer.cstReducer.cstUserinfo.cstUserInfo')
export const cstUserInfoSelector = createSelector(cstUserinfo, u => u)