import USDC from '../../../backEnd/abis/ERC20/masterchef/USDC.json'
import MasterChef from '../../../backEnd/abis/ERC20/masterchef/MasterChef.json'
import Web3 from 'web3'

import { update, loadProvider, loadAccount } from '../../interactions'
import { loadMasterChef } from '../masterchef/interactions'

import {
  usdcContractLoaded,
  usdcUserInfoLoaded,
  usdcMasterChefBalanceLoaded,
  usdcPoolDataLoaded,
} from './actions'

import { loadWeb3Bank } from '../interactions'

export const loadUSDC = async (dispatch, web3, netId) => {
  try {
    if(netId === undefined) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      const usdc = new web3.eth.Contract(USDC.abi, USDC.networks[netId].address)
      dispatch(usdcContractLoaded(usdc))
      return usdc
    } else {
      const usdc = new web3.eth.Contract(USDC.abi, USDC.networks[netId].address)
      dispatch(usdcContractLoaded(usdc))
      return usdc
    }
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load arcmarket: ', e)
    dispatch(usdcContractLoaded(null))
    return null
  }
}



export const usdcUpdate = async (dispatch) => {
  try{
    let account, web3, netId, usdc, masterchef, masterChefAddy
    let poolID = 6

    
    await update(dispatch)
    

    web3 = await loadWeb3Bank(dispatch)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()



    if(web3 === undefined || netId !== 137) {
      let provider = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      web3 = new Web3(provider)
      netId = 137
      masterChefAddy = MasterChef.networks[netId].address
      usdc = await loadUSDC(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)

      await getUSDCBalance(dispatch, usdc, masterChefAddy, account)
      await fetchUSDCPoolData(dispatch, poolID, masterchef)
      await USDCUserInfo(dispatch, account, poolID, masterchef)

    } else {
      masterChefAddy = MasterChef.networks[netId].address
  
      usdc = await loadUSDC(dispatch, web3, netId)
      masterchef = await loadMasterChef(dispatch, web3, netId)
  
      await getUSDCBalance(dispatch, usdc, masterChefAddy, account)
      await fetchUSDCPoolData(dispatch, poolID, masterchef)
      await USDCUserInfo(dispatch, account, poolID, masterchef)
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}

export const getUSDCBalance = async (dispatch, usdc, masterChefAddy, account) => {
  try{
    let mcUSDCBal, userUSDCBal, allowance
    let usdcBalance = []
    let usdcApproveBool = false
    mcUSDCBal = await usdc.methods.balanceOf(masterChefAddy).call() / 10**6
    console.log(account)
    if(account !== null) {
      userUSDCBal = await usdc.methods.balanceOf(account).call() / 10**6
      allowance = await usdc.methods.allowance(account, masterChefAddy).call()  / 10**6

      console.log(allowance)
      if(allowance >= userUSDCBal) {
        usdcApproveBool = true
      }
    } else {
      userUSDCBal = 0
    }

    usdcBalance[0] = {
      mcUSDCBal,
      userUSDCBal,
      usdcApproveBool
    }

    dispatch(usdcMasterChefBalanceLoaded(usdcBalance))

  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}


export const fetchUSDCPoolData = async (dispatch, _poolID, masterchef) => {
  try{
    let usdcPoolInfo
    // USDC poolID = 6
    let poolInfoAdding = await masterchef.methods.poolInfo(_poolID).call()
    let totalAllocPoints = await masterchef.methods.totalAllocPoint().call()
    let ccashPerBlock = await masterchef.methods.ccashPerBlock().call() / 10**18
    let blocksPerYear = 31536000 / 2.1
    

    usdcPoolInfo = {
      poolInfo: poolInfoAdding, 
      totalAllocPoints,
      ccashPerBlock,
      blocksPerYear
    }

    dispatch(usdcPoolDataLoaded(usdcPoolInfo))
    console.log(usdcPoolInfo)
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}

export const USDCUserInfo = async (dispatch, account, _poolID, masterchef) => {
  let usdcUserInfo
  try {
    if(account !== null) {
      let userInfo = await masterchef.methods.userInfo(_poolID, account).call()
      let _ccashPendingCCASH = await masterchef.methods.pendingCCASH(_poolID, account).call()
      let usdcPendingCCASH = _ccashPendingCCASH / 10**18
      usdcUserInfo = {
        rewardDebt: userInfo.rewardDebt,
        depositAmount: userInfo.amount / 10**6,
        usdcPendingCCASH
      }
      dispatch(usdcUserInfoLoaded(usdcUserInfo))
    } else {
      usdcUserInfo = {
        rewardDebt: 0,
        depositAmount: 0,
        usdcPendingCCASH: 0
      }
      dispatch(usdcUserInfoLoaded(usdcUserInfo))
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
  console.log('usdcUserInfo')
  console.log(usdcUserInfo)

}

export const approveUSDC = async (dispatch) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let usdc = await loadUSDC(dispatch, web3, netId)
  try {
    let amount = 1000000000000
    let newAmount = web3.utils.toWei(amount.toString(), 'Ether')
    let masterChefAddy = MasterChef.networks[netId].address
    await usdc.methods.approve(masterChefAddy, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        usdcUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const depositUSDC = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 6
  let newAmount = amount * 10**6
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.deposit(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        usdcUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

export const withdrawUSDC = async (dispatch, amount) => {
  let web3 = await loadWeb3Bank(dispatch)
  let account = await loadAccount(dispatch, web3)
  let netId = await web3.eth.net.getId()
  let _poolID = 6
  let newAmount = amount * 10**6
  let masterchef = await loadMasterChef(dispatch, web3, netId)
  try {
    await masterchef.methods.withdraw(_poolID, newAmount).send({ from: account })
      .once('receipt', receipt => {
        update(dispatch)
        usdcUpdate(dispatch)
      })
  } catch (error) {
    console.log('Error, withdraw: ', error)
  }
}

