import React from 'react'
import { connect } from 'react-redux'
// import { listNFT, safeTransferFrom } from '../../store/interactions'
import CubbySendForm from './CubbySendForm'
import { setApprovalForAll } from '../../store/cubby/interactions'



import {
  accountSelector,
} from '../../store/selectors'

import {
  metadataSelector,
  cubbyContractNFTsSelector,
} from '../../store/cubby/selectors'

import './cubby.css'



const CubbyWallet = (props) => (


  <div className="container text-center">

    <div className="row">
      <div className="col-12">
        {/* Intro */}
        <div className="intro text-center">
          <div className="intro-content">
            <h3 className="mt-3 mb-0">Your cubby wallet</h3>
          </div>
        </div>
      </div>
    </div>
    {!props.metadata.isApprovedForAll ?
      <button
        type="Success"
        className='btn btn-primary btn-bordered-white'
        style={{ width: '50%' }}
        onClick={(e) => setApprovalForAll(props.dispatch)}
      >
        <b>unlock market listing</b>
      </button>
      :
      <p></p>
    }

    <div className="row items">

      {props.cubbyNFTs === undefined ? <div> loading </div> :
        <div className="cubbybox-container">{props.cubbyNFTs.map((nft, key) => {
          if (nft.ownerOf === props.account) {
            return (


              <div className="col-12 col-sm-6 col-lg-3 item mt-3" key={key}>
                <div className="card">
                  <div className="image-over">
                    <a href={nft.tokenURIData.image} target="_blank" rel="noopener noreferrer">
                      <img
                        className="card-img-top"
                        src={nft.tokenURIData.image}
                        alt="Buy and Sell NFT"
                      />
                    </a>
                  </div>
                  <h5>TokenID: {nft.tokenID}</h5>
                  <div className="card-caption col-12 p-0">
                    <div className="card-body">
                      <CubbySendForm tokenID={nft.tokenID} />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}</div>
      }
    </div>
    <p style={{ padding: '5px' }}>
      <a href='https://opensea.io/collection/cryptocubbies' target="_blank" rel="noopener noreferrer">
        <button className='btn btn-primary btn-bordered-white'><b>VIEW ON OPEN SA</b></button>
      </a>
    </p>
  </div>
)

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    metadata: metadataSelector(state),
    cubbyNFTs: cubbyContractNFTsSelector(state),
  }
}

export default connect(mapStateToProps)(CubbyWallet);