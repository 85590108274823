import { combineReducers } from 'redux';

function daiContract(state = {}, action) {
  switch (action.type) {
    case 'DAI_CONTRACT_LOADED':
      return { ...state, loaded: true, dai: action.dai }
    case 'DAI_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, daiBal: action.daiBal }
      case 'DAI_POOL_DATA_LOADED':
        return { ...state, loaded: true, daiPoolData: action.daiPoolData }
    default:
      return state
  }
}

function daiUserinfo(state = {}, action) {
  switch (action.type) {
    case 'DAI_USER_INFO_LOADED':
      return { ...state, loaded: true, daiUserInfo: action.daiUserInfo }
    default:
      return state
  }
}

const daiReducer = combineReducers({
  daiContract,
  daiUserinfo,
})

export default daiReducer