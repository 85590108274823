import React from 'react'
import { connect } from 'react-redux'
// import { listNFT, safeTransferFrom } from '../../store/interactions'
import SOSendForm from './SOSendForm'



import {
  accountSelector,
} from '../../store/selectors'

import {
  soOwnedNFTSelector
} from '../../store/spaceodyssey/selectors'

import './cubby.css'



const SOWallet = (props) => (

  <div className="container text-center">
    <div className="row">
      <div className="col-12">
        {/* Intro */}
        <div className="intro text-center">
          <div className="intro-content">
            <h3 className="mt-3 mb-0">Your SpaceOdyssey Wallet</h3>
          </div>
        </div>
      </div>
    </div>


    <div className="row items">
      {props.soNFTs === undefined ? <div> Loading... </div> :
      
        <div className="cubbybox-container"> {props.soNFTs.map((nft, key) => {
          return (

            <div className="col-12 col-sm-6 col-lg-3 item mt-3" key={key}>
              <div className="card">

                <div className="image-over">
                  <a href={nft.itemData.image} target="_blank" rel="noopener noreferrer">
                    <img
                      className="card-img-top"
                      style={{ height: "200px" }}
                      src={nft.itemData.image}
                      alt='image'
                    />
                  </a>
                </div>

                <h5>TokenID: {nft.itemData.id}</h5>
                <div className="card-caption col-12 p-0">
                  <div className="card-body">
                    <SOSendForm tokenID={nft.itemData.id} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}</div>
      }
    </div>
  </div>
)

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    soNFTs: soOwnedNFTSelector(state),
  }
}

export default connect(mapStateToProps)(SOWallet);