import { createSelector } from 'reselect'
import { get } from 'lodash'


const quickContract = state => get(state, 'DeFiBankReducer.quickReducer.quickContract.quick')
export const quickContractSelector = createSelector(quickContract, c => c)

const quickMasterChefBal = state => get(state, 'DeFiBankReducer.quickReducer.quickContract.quickBal')
export const quickMasterChefBalSelector = createSelector(quickMasterChefBal, b => b)

const quickPoolData = state => get(state, 'DeFiBankReducer.quickReducer.quickContract.quickPoolData')
export const quickPoolDataSelector = createSelector(quickPoolData, p => p)

const quickUserinfo = state => get(state, 'DeFiBankReducer.quickReducer.quickUserinfo.quickUserInfo')
export const quickUserInfoSelector = createSelector(quickUserinfo, u => u)