import { createSelector } from 'reselect'
import { get } from 'lodash'


const soNFTContract = state => get(state, 'SOReducer.soNFTContract.contract')
export const soNFTContractSelector = createSelector(soNFTContract, c => c)

const soMetaData = state => get(state, 'SOReducer.soNFTContract.metadata')
export const soMetaDataSelector = createSelector(soMetaData, d => d)

const soNFT = state => get(state, 'SOReducer.soNFTContract.nfts')
export const soNFTSelector = createSelector(soNFT, n => n)

const soOwnedNFT = state => get(state, 'SOReducer.soNFTContract.owned')
export const soOwnedNFTSelector = createSelector(soOwnedNFT, n => n)

const soOwnedIPFS = state => get(state, 'SOReducer.soNFTContract.ipfs')
export const soOwnedIPFSSelector = createSelector(soOwnedIPFS, n => n)

const soMarketContract = state => get(state, 'SOReducer.soMarket.contract')
export const soMarketContractSelector = createSelector(soMarketContract, m => m)

const soMarket = state => get(state, 'SOReducer.soMarket.listings')
export const soMarketSelector = createSelector(soMarket, m => m)

const soCART = state => get(state, 'SOReducer.soMarket.cart')
export const soCartSelector = createSelector(soCART, m => m)

const soMarketCCASH = state => get(state, 'SOReducer.soMarket.ccash')
export const soMarketCCASHSelector = createSelector(soMarketCCASH, a => a)

