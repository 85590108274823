import { combineReducers } from 'redux';

export function soNFTContract(state = {}, action) {
  switch (action.type) {
    case 'SO_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'SO_METADATA_LOADED':
      return { ...state, loaded: true, metadata: action.metadata }
    case 'SO_NFTS_LOADED':
      return { ...state, loaded: true, nfts: action.nfts }
    case 'SO_OWNEDNFTS_LOADED':
      return { ...state, loaded: true, owned: action.owned }
    case 'SO_IPFS_LOADED':
      return { ...state, loaded: true, ipfs: action.ipfs }
    default:
      return state
  }
}


export function soMarket(state = {}, action) {
  switch (action.type) {
    case 'SOMARKET_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'SOMARKET_NFTS_LOADED':
      return { ...state, loaded: true, listings: action.listings }
    case 'SOMARKET_CART_LOADED':
      return { ...state, loaded: true, cart: action.cart }
    case 'SOMARKET_CCASH_LOADED':
      return { ...state, loaded: true, ccash: action.ccash }
    default:
      return state
  }
}


const SOReducer = combineReducers({
  soNFTContract,
  soMarket
})

export default SOReducer