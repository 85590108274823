import React from 'react'
import { connect } from 'react-redux'
import { purchaseNFT, approveMarket, removeNFT } from '../../store/cubby/interactions'

import {
    accountSelector,
} from '../../store/selectors'

import {
    cubbyMarketNFTsSelector,
    ccashApproveSelector,
} from '../../store/cubby/selectors'

import CubbyUpdatePrice from './CubbyUpdatePrice'

import './cubby.css'
import CubbyHelp from './CubbyHelp'
import { Link } from 'react-router-dom'



const CubbyMarket = (props) => (

    <section className="explore-area load-more p-0">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {/* Intro */}
                    <div className="intro d-flex justify-content-between align-items-end m-0">
                        <div className="intro-content">
                            <span>Exclusive Assets</span>
                            <h3 className="mt-3 mb-0">Explore</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row items">
                {props.listedCubbies === undefined ? <div> &nbsp; &nbsp; Loading... </div> :
                    <div className="cubbybox-container">{props.listedCubbies.slice(1, 5).map((nft, key) => {
                        return (
                            <div className="col-12 col-sm-6 col-lg-3 item " key={key}>
                                <div className="card">
                                    <div className="image-over">
                                        <a href={nft.tokenURIData.image} target="_blank" rel="noopener noreferrer">
                                            <img
                                                className="card-img-top"
                                                src={nft.tokenURIData.image}
                                                alt="Buy and Sell NFT"
                                            />
                                        </a>
                                    </div>
                                    {/* Card Caption */}
                                    <div className="card-caption col-12 p-0">
                                        {/* Card Body */}
                                        <div className="card-body">
                                            <a href="#">
                                                <h5 className="mb-0">MarketID {nft.nftMarketID}</h5>
                                            </a>
                                            {/* <div className="seller d-flex align-items-center my-3">
                                                <span>Owned:</span>
                                                <a href="https://opensea.io/assets/cryptocubbies" target="_blank">
                                                    <h6 className="ml-1 mb-0"> &nbsp; CryptoCubbies</h6>
                                                </a>
                                            </div> */}
                                            <div className="card-bottom d-flex justify-content-between">
                                                <span>{nft.price / 10 ** 18} MATIC + {nft.ccashFee} CCASH</span><br />
                                                {/* <span>1 of 1</span> */}
                                            </div>
                                            <a
                                                className="btn btn-bordered-white btn-smaller mt-3 btn-ccash"
                                                href='https://quickswap.exchange/#/swap?inputCurrency=0x712b76191f2b48F0F1f4Cc0B6793d59FE22f6B2a' target='blank'
                                            >
                                                <i className="fa fa-shopping-cart mr-2" aria-hidden="true"></i>
                                                Buy $CCASH
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        )
                    })}</div>
                }
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <Link className="btn btn-bordered-white mt-5" to="/CryptoCubbies">
                        Load More
                    </Link>
                </div>
            </div>
        </div>
    </section>
)

function mapStateToProps(state) {
    return {
        account: accountSelector(state),
        listedCubbies: cubbyMarketNFTsSelector(state),
        ccashApprove: ccashApproveSelector(state),
    }
}

export default connect(mapStateToProps)(CubbyMarket);