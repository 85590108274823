import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import SOLoading from './SOLoading'


import {
  accountSelector,
} from '../../store/selectors'

import {
  soNFTSelector,
  soMarketSelector,
} from '../../store/spaceodyssey/selectors'

import SOMarket from './SOMarket'
import SOWallet from './SOWallet'

import FooterNew from '../FooterNew'




class SOMain extends Component {



  render(props) {
    try {
      return (
        <div>

          <div>
            <SOMarket />
            <SOWallet />
            <div className="container pt-5 pb-5">
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-12">
                  <p>For The Nerds </p>
                  Market Addy:&nbsp;
                  <a
                    href={`https://polygonscan.com/address/0x8492b8f64Ef4c0c932e8Fe8C3aA7Ac01C9520E7F`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#352095" }}
                  >
                    0x8492b8f64Ef4c0c932e8Fe8C3aA7Ac01C9520E7F
                  </a>
                </div>
              </div>
            </div>
          </div>
          <FooterNew />
        </div>
      )
    } catch (e) {
      return (
        <SOLoading />
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    nfts: soNFTSelector(state),
    listings: soMarketSelector(state),
  }
}

export default connect(mapStateToProps)(SOMain)


