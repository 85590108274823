import { combineReducers } from 'redux';

import { masterchefContract } from './masterchef/reducers'
import cstReducer from './cst/reducers'
import ccashReducer from './ccash/reducers'
import wmaticReducer from './wrappedmatic/reducers'
import wethReducer from './wrappedethereum/reducers'
import wbtcReducer from './wrappedbitcoin/reducers'
import quickReducer from './quick/reducers'
import usdcReducer from './usdc/reducers'
import usdtReducer from './usdt/reducers'
import daiReducer from './dai/reducers'


const DeFiBankReducer = combineReducers({
  masterchefContract,
  cstReducer,
  ccashReducer,
  wmaticReducer,
  wethReducer,
  wbtcReducer,
  quickReducer,
  usdcReducer,
  usdtReducer,
  daiReducer
})

export default DeFiBankReducer