import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css'
import MarketsDropdown from './MarketsDropdown';
import SODropdown from './SODropdown';

import metaMaskImg from '../../images/metamask.png';

import { connect } from 'react-redux'

import eth from '../../images/eth.png'
import matic from '../../images/matic-token.png'
import ccashRound from '../../images/ccash-round.png'

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'

import {
  accountSelector,
  balanceSelector,
  networkSelector,
  web3Selector,
  ccashBalSelector
} from '../../store/selectors'
import MobileNavbar from './MobileNavbar';


function Navbar(props) {

  const [click, setClick] = useState(false);
  const [marketsdropdown, setMarketsdropdown] = useState(false);
  const [sodropdown, setSOdropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <MobileNavbar />
      <nav className='navbar d-none d-md-block d-lg-block d-sm-block'>
        <div className="container">
          <Link to='/' className='navbar-logo'>
            Coinopolis
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>

            <li
              className='nav-item'
              onMouseEnter={() => setMarketsdropdown(true)}
              onMouseLeave={() => setMarketsdropdown(false)}
            >
              <Link
                to='/CryptoCubbies'
                className='nav-links'
                onClick={closeMobileMenu}>
                Markets <i className='fas fa-caret-down' />
              </Link>
              {marketsdropdown && <MarketsDropdown submenu={'submenu-submenu'} />}

            </li>
            <li
              className='nav-item'
              onMouseEnter={() => setSOdropdown(true)}
              onMouseLeave={() => setSOdropdown(false)}
            >
              <Link
                to='/SOMarket'
                className='nav-links'
                onClick={closeMobileMenu}>
                SpaceOdyssey <i className='fas fa-caret-down' />
              </Link>
              {sodropdown && <SODropdown submenu={'submenu-submenu'} />}

            </li>
            <li className='nav-item'>
              <Link
                to='/Farm'
                className='nav-links'
                onClick={closeMobileMenu}>
                Farm
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/Blog'
                className='nav-links'
                onClick={closeMobileMenu}>
                Blog
              </Link>
            </li>

            <li className='nav-item'>
              {props.account

                ? <>


                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <span className="">
                        <b>{props.balance} </b>
                        {props.network !== 'Private' ?
                          <img src={eth} width='18' height='18' alt="eth" /> :
                          <img src={matic} width='18' height='18' alt="matic" />
                        }
                      </span>
                      <span className="">
                        <b>{Math.round(props.ccashBalance)} </b>
                        <img src={ccashRound} width='18' height='18' alt="ccash" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="">{props.network}</Dropdown.Item>
                      <Dropdown.Item href={props.account}>
                        <div className="">
                          <i className="fas fa-wallet"></i>
                          {props.network === 'Main' || props.network === 'Private' || props.network === 'Wrong network'
                            ? <b><a
                              style={{ color: "#55FF55" }}
                              href={`https://etherscan.io/address/` + props.account}
                              target="_blank"
                              rel="noopener noreferrer"
                            >

                              {props.account.substring(32, 42) + '...'}
                              &nbsp;
                            </a></b>
                            : <b><a
                              style={{ color: "#55FF55" }}
                              href={`https://${props.network}.etherscan.io/address/` + props.account}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {props.account.substring(32, 42) + '...'}
                            </a></b>
                          }
                        </div></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
                :
                <ul className="navbar-nav ml-auto">
                  {props.web3
                    ? <button
                      type="Success"
                      // className="btn btn-outline btn-block "
                      className="btn ml-lg-auto btn-bordered-white"
                      // style={{ backgroundColor: "#55FF55", color: "#000000" }}
                      onClick={async () => {
                        try {
                          await window.ethereum.enable()
                        } catch (e) {
                          console.log(e)
                        }
                      }}
                    >
                      <img src={metaMaskImg} className="icon-wallet mr-md-2" height="20px" />

                      L o g i n
                    </button>
                    : <button
                      // className="btn btn-warning"
                      className="btn ml-lg-auto btn-bordered-white"

                      type="button"
                      onClick={() => {
                        try {
                          window.open("https://metamask.io/")
                        } catch (e) {
                          console.log(e)
                        }
                      }}
                    >
                      <img src={metaMaskImg} className="icon-wallet mr-md-2" height="20px" />

                      Get MetaMask
                    </button>
                  }
                </ul>
              }
            </li>
          </ul>

        </div>
      </nav >
    </>
  );
}

// export default Navbar;

function mapStateToProps(state) {
  return {
    web3: web3Selector(state),
    account: accountSelector(state),
    network: networkSelector(state),
    balance: balanceSelector(state),
    ccashBalance: ccashBalSelector(state)
  }
}

export default connect(mapStateToProps)(Navbar)
