
import SONFT from '../../backEnd/abis/mainnet/spaceodyssey/SpaceOdyssey.json'
// import SOMarket from '../../backEnd/abis/mainnet/spaceodyssey/SpaceOdysseyMarket.json'
import SOMarket from '../../backEnd/abis/mainnet/spaceOdysseyV2/SpaceOdysseyMarketv2.json'
import CCASH from '../../backEnd/abis/ERC20/masterchef/CCASH.json'
import Web3 from 'web3'

import { CCASH_ADDRESS, SONFT_ADDRESS, SOMARKETV2_ADDRESS } from '../config.json'
// SO_ADDRESS = "0x7f8aCe34108fB20D15b756b7133Ca3748E9677D5"
import { ethers } from 'ethers'

import { update, loadProvider, loadAccount } from '../interactions'

import {
  soNFTContractLoaded,
  soCCASHApproveLoaded,
  soNFTsLoaded,
  soOwnedNFTsLoaded,
  soOwnedIPFSLoaded,
  soMarketNFTsLoaded,
  soMarketLoaded,
  soMetaDataLoaded,
  soCartLoaded
} from './actions'


export const loadSONFT = async (dispatch, provider) => {
  try {
    const sonft = new ethers.Contract(SONFT_ADDRESS, SONFT.abi, provider)
    dispatch(soNFTContractLoaded(sonft))
    return sonft
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load so nft: ', e)
    dispatch(soNFTContractLoaded(null))
    return null
  }
}

export const loadSOMarketV2 = async (dispatch, provider) => {
  try {
    const somarketV2 = new ethers.Contract(SOMARKETV2_ADDRESS, SOMarket.abi, provider)
    dispatch(soMarketLoaded(somarketV2))
    return somarketV2
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load somarket: ', e)
    dispatch(soMarketLoaded(null))
    return null
  }
}

export const soUpdate = async (dispatch) => {
  try{
    let account, provider, netId, sonft, somarket
    await update(dispatch)

    provider = await loadProvider(dispatch)
    account = await loadAccount(dispatch)
    let { chainId } = await provider.getNetwork()
    chainId = Number(chainId)

    if(chainId === 137) {
      
      console.log("running this one!")
      somarket = await loadSOMarketV2(dispatch, provider)
      sonft = await loadSONFT(dispatch, provider)
      let ccash = new ethers.Contract(CCASH_ADDRESS, CCASH.abi, provider)
      await loadCcashSO(dispatch, ccash, account, SOMARKETV2_ADDRESS )
      await isApproved(dispatch, sonft, SONFT_ADDRESS, account)
      await fetchSONFTs(dispatch, sonft, somarket)

      await fetchMarketNFTs(dispatch, somarket)
      await fetchOwnedSONFTs(dispatch, sonft, somarket, account)

    } else {
      console.log("running ELSE!")

      // let url = "https://polygon-mumbai.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      let url = "https://polygon-mainnet.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      let provider = await new ethers.JsonRpcProvider(url)

      sonft = await loadSONFT(dispatch, provider)
      somarket = await loadSOMarketV2(dispatch, provider)

      await fetchSONFTs(dispatch, sonft, somarket)
      await fetchMarketNFTs(dispatch, somarket)
      // await fetchSONFTs(dispatch, sonft)

    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}


export const isApproved = async (dispatch, sonft, soNFTAddy, account) => {
  let isApprovedForAll = false
  try {
    isApprovedForAll = await sonft.isApprovedForAll(soNFTAddy, account)
    dispatch(soMetaDataLoaded(isApprovedForAll))
  } catch (e) {
    dispatch(soMetaDataLoaded(isApprovedForAll))
    console.log(e)
  }
}


export const fetchMarketNFTs = async (dispatch, somarket) => {
  try{
    let _marketListingData = []
    let marketListingData = []
    // let productCount

    if(somarket === undefined) {
      // let url = "https://polygon-mumbai.infura.io/v3/7cd59ceb4174433495dd6c4b52a20297"
      let url = "https://polygon-mainnet.infura.io/v3/1e3fc3a9cf3748249d542e88fc484d86"
      let provider = new ethers.JsonRpcApiProvider(url)
      somarket = new ethers.Contract(SOMARKETV2_ADDRESS, SOMarket.abi, provider)
      // productCount = Number(await somarket.productCount())
      console.log('if')

    // } else {
      // productCount = Number(await somarket.productCount())
    }


    // for(let i = 1; i <= productCount; i++) {
      // let item = await somarket.products(i)
      _marketListingData = await somarket.fetchProducts()
      console.log(_marketListingData.length)
      // for(let i = 0; i < _marketListingData.length; i++) {
      //   console.log(i)
      //   console.log(i)
      //   console.log(i)
      //   console.log(i)
      //   console.log(_marketListingData[i])
      // }

      for(let i = 0; i < _marketListingData.length; i++) {
        let image
        if(websiteAllTokenData[i].id !== 0) {
          image = websiteAllTokenData[i].image
        }
        let obj = {
          id: _marketListingData[i].id,
          tokenID: _marketListingData[i].tokenID,
          tokenAmount: _marketListingData[i].tokenAmount,
          price: _marketListingData[i].price,
          tokenURI: _marketListingData[i].tokenURI,
          purchased: _marketListingData[i].purchased,
          image

        }
       
        marketListingData.push(obj)
      }

      // marketListingData.push(item)
    // }

    dispatch(soMarketNFTsLoaded(marketListingData))
  } catch (e) {
    console.log('Error, unable to load market listing data', e)
  }
}



let websiteAllTokenData = []

export const fetchSONFTs = async (dispatch, sonft, somarketV2) => {

  try{

    // const tokenCount = await sonft.methods.tokenID()
    let newWebsiteAllTokenData = await somarketV2.fetchAllNFTS()

    for(let i = 0; i < newWebsiteAllTokenData.length; i++) {
      let tokenURI = newWebsiteAllTokenData[i][2]

      if(tokenURI !== "") {
        const response = await fetch(tokenURI)
        const itemData = await response.json()
        websiteAllTokenData.push(itemData)
        }
        //  else {
        //   websiteAllTokenData[i] = 'no uri';
        // }

    }

    // for(let i = 1; i <= tokenCount; i++) {
    //   let _tokenURI = await sonft.methods.tokenURI(i).call()


    //   if(_tokenURI !== '') {
    //     console.log("hello")
        
    //     const response = await fetch(_tokenURI)
    //     const itemData = await response.json()
    //     console.log(itemData)
    //     websiteAllTokenData[i] = itemData;
        
    //   } else {
    //     websiteAllTokenData[i] = 'no uri';
    //   }
      
    // }
    dispatch(soNFTsLoaded(websiteAllTokenData))
  } catch (e) {
    console.log('Error, unable to fetch NFT data', e)
  }
}

export const fetchOwnedSONFTs = async (dispatch, sonft, somarketV2, account) => {
  try{
    // const tokenCount = await sonft.methods.tokenID().call()
    let walletAllTokenData = []
    let walletIPFSData = []

    let tokenData = await somarketV2.fetchOwnedNFTS(account)

    for(let i = 0; i < tokenData.length; i++) {
      let tokenBal = Number(tokenData[i][1])
      if(tokenBal > 0) {
        let tokenURI = tokenData[i][2]
        walletIPFSData.push(tokenURI)
        if(tokenURI !== '') {
          const response = await fetch(tokenURI)
          const itemData = await response.json()
          let data = {
            itemData,
            tokenBal
          };
          walletAllTokenData.push(data)
        } else {
          let data = 'no uri';
          let itemData = {
            attack: 0,
            attackRate: 0,
            description: "na",
            energy: 0,
            equipType: "Head",
            health: 0,
            id: 0,
            image: "na",
            imageTop: "na",
            name: "NA",
            regenRate: 0
          }
          data = {
            itemData,
            data
          }
          walletAllTokenData.push(data)
        }
      }

    }



    // for(let i = 1; i <= tokenCount; i++) {
    //   let _token = await sonft.methods.balanceOf(account, i).call()
    //   if(_token >= 1) {
    //     let _tokenURI = await sonft.methods.tokenURI(i).call()
    //     walletIPFSData.push(_tokenURI)
    //     if(_tokenURI !== '') {
    //       const response = await fetch(_tokenURI)
    //       const itemData = await response.json()
    //       walletAllTokenData[i] = {
    //         itemData,
    //         _token
    //       };
    //     } else {
    //       walletAllTokenData[i] = 'no uri';
    //     }
    //   }
    // }

    dispatch(soOwnedIPFSLoaded(walletIPFSData))

    dispatch(soOwnedNFTsLoaded(walletAllTokenData))
  } catch (e) {
    console.log('Error, unable to load owned NFT data', e)
  }
}

export const loadCcashSO = async (dispatch, ccash, account) => {
  try{
    const preBalance = await ccash.balanceOf(account)
    const balance = Number(preBalance) / 10**18
    let market_allowanceBool

    let market_preAllowance = await ccash.allowance(account, SOMARKETV2_ADDRESS)
    let market_allowance = Number(market_preAllowance) / 10**18

    if(market_allowance > 100000000) {
      market_allowanceBool = true
    } else {
      market_allowanceBool = false;
    }
    console.log(market_allowance)

    let ccashInfo = {
      market_allowanceBool,
      balance
    }

    dispatch(soCCASHApproveLoaded(ccashInfo))
  } catch (e) {
    console.log('Error, load images', e)
  }
}



export const bulkPurchase = async (dispatch, cart) => {
  try {
    const provider = await loadProvider(dispatch)
    const account = await loadAccount(dispatch,)
    // const netId = await web3.eth.net.getId()
    const somarket = await loadSOMarketV2(dispatch, provider)
    const signer = await provider.getSigner()

    let newTotals = 0
    cart.prices.forEach(price => {
      newTotals += price
    });
    if(newTotals < cart.total) {
      console.log("--------------------")
      console.log("ERROR")
      console.log(`new total:   ${newTotals}`)
      console.log(`old total:   ${cart.total}`)
      console.log("--------------------")

    }
    await somarket.connect(signer).bulkPurchaseProduct(cart.items.tokenIDs, cart.items.prices)
    .on('receipt', async (r) => {
      soUpdate(dispatch)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
  } catch (e) {
    console.log('Error, buy NFT', e)
  }
}


export const approveMarket = async (dispatch) => {
  try{
    const provider = await loadProvider(dispatch)
    const account = await loadAccount(dispatch)
    // const netId = await provider.getNetwork()
    const ccash = new ethers.Contract(CCASH_ADDRESS, CCASH.abi, provider)

    let amount = 10000000000
    let newAmount = ethers.parseUnits(amount.toString(), 18)

    const signer = await provider.getSigner()

    await ccash.connect(signer).approve(SOMARKETV2_ADDRESS, newAmount)
      .on('receipt', async (r) => {
        soUpdate(dispatch)
      })
      .on('error',(error) => {
        console.error(error)
        window.alert(`There was an error!`)
      })
  } catch (e){
    console.log('Error, approve market', e)
  }
  
}

export const purchaseNFT = async (dispatch, nftMarketID, amount) => {
  try {
    const provider = await loadProvider(dispatch)
    const account = await loadAccount(dispatch,)
    // const netId = await web3.eth.net.getId()
    const somarket = await loadSOMarketV2(dispatch, provider)
    const signer = await provider.getSigner()

    await somarket.connect(signer).purchaseProduct(nftMarketID, amount)
    .on('receipt', async (r) => {
      soUpdate(dispatch)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
  } catch (e) {
    console.log('Error, buy NFT', e)
  }
}

export const safeTransferFrom = async (dispatch, addressTo, tokenID) => {
  try{
    const provider = await loadProvider(dispatch)
    const account = await loadAccount(dispatch)
    // const netId = await web3.eth.net.getId()
    const sonft = await loadSONFT(dispatch, provider)

    const signer = await provider.getSigner()


    await sonft.connect(signer).safeTransferFrom(account, addressTo, tokenID, 1)
    .on('receipt', async (r) => {
      soUpdate(dispatch)
    })
    .on('error',(error) => {
      console.error(error)
      window.alert(`There was an error!`)
    })
} catch (e){
    console.log('Error, sending NFT', e)
  }
}

