import { combineReducers } from 'redux';

function usdtContract(state = {}, action) {
  switch (action.type) {
    case 'USDT_CONTRACT_LOADED':
      return { ...state, loaded: true, usdt: action.usdt }
    case 'USDT_MASTERCHEFBAL_LOADED':
      return { ...state, loaded: true, usdtBal: action.usdtBal }
      case 'USDT_POOL_DATA_LOADED':
        return { ...state, loaded: true, usdtPoolData: action.usdtPoolData }
    default:
      return state
  }
}

function usdtUserinfo(state = {}, action) {
  switch (action.type) {
    case 'USDT_USER_INFO_LOADED':
      return { ...state, loaded: true, usdtUserInfo: action.usdtUserInfo }
    default:
      return state
  }
}

const usdtReducer = combineReducers({
  usdtContract,
  usdtUserinfo,
})

export default usdtReducer